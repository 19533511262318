<template>
    <div>SMS log page</div>
</template>

<script>
    export default {
        name: "Smslogs"
    }
</script>

<style scoped>

</style>
