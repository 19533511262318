<template>
        <div class="topnav">
            <ul>
                <li class="flex flex-col itmes-center justify-center" :class="{'active': isVoiceIn}" @click="setCurrentTab(0)">
                    <font-awesome-icon icon="phone" class="mb-1" />
                    <p>Voice In</p>
                </li>
                <li class="flex flex-col itmes-center justify-center" :class="{'active': isVoiceOut}" @click="setCurrentTab(1)">
                    <font-awesome-icon icon="phone-alt" class="mb-1"/>
                    Voice Out
                </li>
                <li class="flex flex-col itmes-center justify-center" :class="{'active': isSMSIn}" @click="setCurrentTab(2)">
                    <font-awesome-icon icon="envelope" class="mb-1" />
                    SMS IN
                </li>
                <li class="flex flex-col items-center justify-center" :class="{'active': isSMSOut}" @click="setCurrentTab(3)">
                    <font-awesome-icon icon="envelope-open" class="mb-1" />
                    SMS OUT
                </li>
                <li class="flex flex-col items-center justify-center" :class="{'active': isHelp}" @click="setCurrentTab(4)">
                    <font-awesome-icon icon="question-circle" class="mb-1"/>
                    Help
                </li>
            </ul>
        </div>
        <div v-if="currentTab == 0" class="body-section">
            <VoiceInput />
        </div>
        <div v-else-if="currentTab == 1" class="body-section">
            <VoiceOutput />
        </div>
        <div v-else-if="currentTab == 2" class="body-section">
            <SmsInput />
        </div>
        <div v-else-if="currentTab == 3" class="body-section">
            <SmsOutput />
        </div>
        <div v-else-if="currentTab == 4" class="body-section">
            <Help />
        </div>
        <div v-else></div>
</template>

<script>
    import {computed, onMounted, reactive, ref} from 'vue'
    import VoiceInput from "./Trunks/VoiceInput";
    import VoiceOutput from "./Trunks/VoiceOutput";
    import SmsInput from "./Trunks/SmsInput";
    import SmsOutput from "./Trunks/SmsOutput";
    import Help from "./Trunks/Help";
    export default {
        name: "Trunks",
        components:{
            VoiceInput,
            VoiceOutput,
            SmsInput,
            SmsOutput,
            Help
        },
        setup(){
            const currentTab = ref(0);
            const isVoiceIn = ref(true);
            const isVoiceOut = ref(false);
            const isSMSIn = ref(false);
            const isSMSOut = ref(false);
            const isHelp = ref(false);
            const setCurrentTab = (value) => {
                currentTab.value = value;
                isVoiceIn.value = false;
                isVoiceOut.value = false;
                isSMSIn.value = false;
                isSMSOut.value = false;
                isHelp.value = false;
                if(value == 0){
                    isVoiceIn.value = true;
                }else if(value ==1){
                    isVoiceOut.value = true;
                }else if(value ==2){
                    isSMSIn.value = true;
                }else if(value ==3){
                    isSMSOut.value = true;
                }else if(value ==4){
                    isHelp.value = true;
                }

            }

            return{
                currentTab,
                setCurrentTab,
                isVoiceIn,
                isVoiceOut,
                isSMSIn,
                isSMSOut,
                isHelp
            }
        }
    }
</script>

<style scoped>
    .topnav {
        --tw-bg-opacity: 1;
        background-color: rgba(155, 155, 197, var(--tw-bg-opacity));
        overflow: hidden;
        text-align: left;
    }

    .topnav li {
        float: left;
        color: #f2f2f2;
        text-align: center;
        padding: 16px 18px;
        text-decoration: none;
        font-size: 17px;
        cursor: pointer;
        width:115px;
        height:90px;
    }

    .topnav li:hover {
        background-color: #ddd;
        color: black;
    }

    .topnav li.active {
        background-color: lightgrey;
        color: black;
    }
</style>


