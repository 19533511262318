<template>
  <div class="voicein-head m-1">
    <div class="top-section flex flex-row justify-between itmes-center">
      <div class="flex flex-row">
        <p class="text-2xl text-gray-600 p-4 text-left">
          SMS OUT Trunk Profiles
        </p>
      </div>
      <div></div>
    </div>
    <hr class="mx-4" />
    <div
      v-if="!noTrunks"
      class="flex flex-col items-center justify-center m-4 p-6 bg-gray-100"
    >
      <div class="text-2xl text-gray-600 p-4 text-center">
        You have no SMS Trunks
      </div>
      <p class="my-1 cursor-pointer hover:text-gray-500">
        Click here for more information
      </p>
    </div>
    <div v-else class="m-4 p-6 bg-gray-100" style="border-radius: 10px">
      <div class="search-section flex flex-row justify-start items-center m-2">
        <div class="">
          <label class="text-lg mx-2">SearchBy:</label
          ><input
            class="h-10"
            placeholder="Search"
            v-model="searchTerm"
            style="border: solid 1px lightgrey; border-radius: 4px"
          />
        </div>
        <div
          class="flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500"
          style="border-radius: 6px"
          @click.prevent=""
        >
          <font-awesome-icon icon="search" class="fa-fw fa-lg mx-1" />
          <p class="mx-1">Filter</p>
        </div>
      </div>
      <table-lite
        :has-checkbox="true"
        :is-slot-mode="true"
        :is-static-mode="true"
        :is-loading="table.isLoading"
        :columns="table.columns"
        :rows="table.rows"
        :total="table.totalRecordCount"
        @is-finished="tableLoadingFinish"
        @return-checked-rows="updateCheckedRows"
      >
      </table-lite>
      <div
        class="form-group batch-action flex flex-row justify-start items-center m-4 p-4"
      >
        <select
          id="batch-action"
          name="batch-action"
          class="h-10 w-44"
          style="border: solid 1px lightgrey; border-radius: 4px"
        >
          <option value="" disabled selected>Select batch action</option>
          <option
            v-for="(item, index) in batchActions"
            :value="item"
            :key="index"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from "vue";
import TableLite from "vue3-table-lite";
import vClickOutside from "click-outside-vue3";
export default {
  name: "SmsOutput",
  components: {
    TableLite,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const noTrunks = ref(true);
    const searchTerm = ref("");
    const addNewList = ref(false);
    const hiddenListMenu = () => {
      addNewList.value = false;
    };
    const showAddNewList = () => {
      addNewList.value = !addNewList.value;
    };
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "name",
          width: "15%",
          sortable: true,
        },
        {
          label: "Type",
          field: "type",
          width: "5%",
          sortable: true,
        },
        {
          label: "Credentials",
          field: "credentials",
          width: "15%",
        },
        {
          label: "Allowed IPs",
          field: "allowedips",
          width: "10%",
        },
        {
          label: "Callback URL",
          field: "callbackurl",
          width: "30%",
        },
        {
          label: "Send",
          field: "send",
          width: "10%",
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      rows: [],
      searchResult: [],
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      // sortable: {
      //   order: "itemname",
      //   sort: "asc",
      // },
    });
    const tableLoadingFinish = (elements) => {
      table.isLoading = false;
    };
    /** Row checked event */
    const updateCheckedRows = (rowsKey) => {};
    return {
      hiddenListMenu,
      addNewList,
      showAddNewList,
      noTrunks,
      tableLoadingFinish,
      table,
      updateCheckedRows,
      searchTerm,
    };
  },
  data() {
    return {
      batchActions: [{ name: "Remove trunks" }],
    };
  },
  mounted() {
    this.table.rows = [
      {
        name: "SMS trunk 1",
        type: "HTTP",
        credentials: "*****************",
        allowedips: "128.0.0.1",
        callbackurl: "https://sms.xlinx.eu/sms",
        send: "image",
        actions: "actions",
      },
    ];
  },
  methods: {},
};
</script>

<style scoped></style>
