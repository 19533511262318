<template>
    <div
        class="custom-switch"
        :class="{ 'custom-switch-on': checked }"
        @click="toggle"
    ></div>
</template>

<script>
    export default {
        name: "RSwitch",
        model:{
            prop:"checked",
            event:"change"
        },
        props:{
            checked: {
                type: Boolean,
                default: false
            }
        },
        methods:{
            toggle(){
                this.$emit("change", !this.checked);
            }
        }
    }
</script>

<style scoped>
    .custom-switch {
        display: inline-block;
        position: relative;
        width: 42px;
        height: 22px;
        border: 1px solid #dfdfdf;
        outline: 0;
        border-radius: 16px;
        box-sizing: border-box;
        background-color: #dfdfdf;
        transition: background-color 0.1s, border 0.1s;
        cursor: pointer;
    }
    .custom-switch:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 20px;
        border-radius: 15px;
        background-color: #fdfdfd;
        transition: transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
    }
    .custom-switch:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #ffffff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
        transition: transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
    }
    .custom-switch-on {
        border-color: #5651ff;
        background-color: #5651ff;
    }
    .custom-switch-on:before {
        border-color: rgba(86, 81, 255);
        background-color: #5651ff;
    }
    .custom-switch-on:after {
        transform: translateX(20px);
    }
</style>
