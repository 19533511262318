<template>
  <transition leave-active-class="duration-200">
    <div
      v-show="show"
      class="fixed w-screen h-screen inset-0 overflow-y-auto"
      style="z-index: 99"
    >
      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-show="show"
          class="absolute inset-0 transform transition-all bg-gray-50 bg-opacity-50"
          @click="close"
        ></div>
      </transition>

      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100 translate-y-0 sm:scale-100"
        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          v-show="show"
          class="flex justify-center overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full z-50"
        >
          <slot></slot>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { watchEffect } from "vue";
export default {
  name: "WidgetModal",
  emits: ["close"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    watchEffect(() => {
      if (props.show) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = null;
      }
    });
    const close = () => {
      emit("close", "close");
    };
    return {
      close,
    };
  },
};
</script>

<style scoped></style>
