<template>
  <div class="main-section relative">
    <Widget />
  </div>
</template>

<script>
import Widget from '../widget/index'
export default {
  name: 'main-section',
  components: {
    Widget
  }
}
</script>

<style scoped>
.main-section {
  height: calc(100vh - 57px);
  overflow-y: auto;
}
</style>
