<template>
  <WidgetModal :show="show" @close="onClose">
    <div class="lg:max-w-883p w-full mx-auto h-full px-3">
      <div
        class="overflow-x-hidden"
        style="min-height: calc(100% - (1.75rem * 2))"
      >
        <div class="my-6">
          <div
            class="bg-white relative rounded-lg"
            style="border: solid 1px lightgrey"
          >
            <div
              class="absolute top-6 right-8 h-6 w-6 cursor-pointer"
              @click="onClose"
            >
              <img
                src="@/assets/images/close.svg"
                class="hover:opacity-50"
                alt=""
              />
            </div>
            <div class="py-6 px-4">
              <p class="text-2xl text-left pl-1">Purchase channels</p>
              <hr class="mt-3" />
            </div>
            <div class="pnl_table p-2">
              <table-lite
                :is-slot-mode="true"
                :is-static-mode="true"
                :is-loading="table.isLoading"
                :columns="table.columns"
                :rows="table.rows"
                :total="table.totalRecordCount"
                @is-finished="tableLoadingFinish"
                @return-checked-rows="updateCheckedRows"
              >
              </table-lite>
              <div
                class="form-group batch-action flex flex-row justify-around items-center p-2"
              >
                <div>VAT: (0.0%) &nbsp; $0.00</div>
                <div>Gross Amount: &nbsp; $33.06</div>
              </div>
            </div>
            <hr />
            <div class="flex flex-row justify-end itmes-center m-2">
              <div
                class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-gray-300 h-8 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500"
                style="border-radius: 6px"
                @click.prevent="onClose"
              >
                <p class="mx-2">Close</p>
              </div>
              <div
                class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-8 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500"
                style="border-radius: 6px"
                @click.prevent="submitData"
              >
                <p class="ml-1">Procced to checkout</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WidgetModal>
</template>

<script>
import { ref, computed, reactive, onMounted } from 'vue'
import WidgetModal from '@/shared/WidgetModal'
import TableLite from 'vue3-table-lite'

export default {
  name: 'AddChannelPool',
  emits: ['close'],
  components: {
    WidgetModal,
    TableLite
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const onClose = () => {
      emit('close', 'close')
    }
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: 'Service',
          field: 'service',
          width: '30%',
          sortable: true
        },
        {
          label: 'Setup',
          field: 'setup',
          width: '15%'
        },
        {
          label: 'Monthly',
          field: 'monthly',
          width: '15%'
        },
        {
          label: 'Quantity',
          field: 'quantity',
          width: '20%'
        },
        {
          label: 'Total',
          field: 'total',
          width: '20%'
        }
      ],
      rows: [],
      searchResult: [],
      totalRecordCount: computed(() => {
        return table.rows.length
      })
      // sortable: {
      //   order: "itemname",
      //   sort: "asc",
      // },
    })
    const tableLoadingFinish = elements => {
      table.isLoading = false
      Array.prototype.forEach.call(elements, function (element) {
        if (element.classList.contains('qty-input')) {
          element.addEventListener('change', function () {
            //this.dataset.subtotal =  this.dataset.selfQTY + this.dataset.activeprice;
          })
        }
      })
    }
    /** Row checked event */
    const updateCheckedRows = rowsKey => {}
    onMounted(async () => {
      table.rows = [
        {
          service: 'Channels Biling Interval: 05 Apr, 2022 - 07 Aug, 2022',
          setup: '$25.00',
          monthly: '$8.06',
          quantity: '1',
          total: '$33.06'
        }
      ]
    })
    return {
      onClose,
      table,
      updateCheckedRows,
      tableLoadingFinish
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped></style>
