<template>
  <div class="coverage-head m-1">
    <p class="text-2xl text-gray-600 p-4 text-left">
      Coverage, Pricing and Purchasing
    </p>
    <hr class="mx-4" />
  </div>
  <div class="coverage-select mx-5 my-6 p-4 flex flex-row bg-gray-100">
    <div class="w-2/5">
      <p class="text-lg my-2 text-left ml-4">
        Show only numbers that support ALL checked services:
      </p>
      <hr class="mx-4" />
      <div class="form-group text-md py-3 flex flex-row">
        <div class="flex-1">
          <input
            type="checkbox"
            id="voiceIn"
            name="voiceIn"
            class="bg-green-400"
            style="background: green"
          />
          <label for="voiceIn" class="text-md"> Voice IN</label><br />
        </div>
        <div class="flex-1">
          <input
            type="checkbox"
            id="voiceOut"
            name="voiceOut"
            class="bg-green-400"
            style="background: green"
          />
          <label for="voiceOut" class="text-md"> Voice OUT</label><br />
        </div>
        <div class="flex-1">
          <input
            type="checkbox"
            id="t38Fax"
            name="t38Fax"
            class="bg-green-400"
            style="background: green"
          />
          <label for="t38Fax" class="text-md"> T.38 Fax</label><br />
        </div>
        <div class="flex-1">
          <input
            type="checkbox"
            id="smsIn"
            name="smsIn"
            class="bg-green-400"
            style="background: green"
          />
          <label for="smsIn" class="text-md"> SMS IN</label><br />
        </div>
        <div class="flex-1">
          <input
            type="checkbox"
            id="smsOut"
            name="smsOut"
            class="bg-green-400"
            style="background: green"
          />
          <label for="smsOut" class="text-md"> SMS OUT</label><br />
        </div>
      </div>
      <div v-if="!visibleRegion" class="form-group pb-3 flex-1">
        <div class="text-left mx-3 my-1">
          <label for="country_list_true" class="text-lg text-gray-500"
            >Select Country:</label
          >
        </div>
        <select
          id="country_list_true"
          name="timezone"
          form="country_list_true"
          v-model="country_name"
          class="mx-3 my-1 block border-1 border-b-half border-light-app-purple w-full h-8 focus:outline-none placeholder-gray-500 text-lg"
          @change="callFetchCities()"
        >
          <option value="" disabled selected>Select Country</option>
          <option
            v-for="(item, index) in countryInfo"
            :value="item"
            :key="index"
          >
            {{ item.country }}
          </option>
        </select>
      </div>
      <div v-else class="flex flex-row itmes-center justify-between">
        <div class="form-group pb-3 px-1 flex-1">
          <div class="text-left mx-3 my-1">
            <label for="country_list" class="text-lg text-gray-500"
              >Select Country:</label
            >
          </div>
          <select
            id="country_list"
            name="timezone"
            form="country_list"
            v-model="country_name"
            class="mx-3 my-1 block border-1 border-b-half border-light-app-purple w-full h-8 focus:outline-none placeholder-gray-500 text-lg"
            @change="callFetchCities()"
          >
            <option value="" disabled selected>Select Country</option>
            <option
              v-for="(item, index) in countryInfo"
              :value="item"
              :key="index"
            >
              {{ item.country }}
            </option>
          </select>
        </div>
        <div class="form-group pb-3 px-1 flex-1">
          <div class="text-left mx-3 my-1">
            <label for="regions_list" class="text-lg text-gray-500"
              >Select Regions:</label
            >
          </div>
          <select
            id="regions_list"
            name="regions_list"
            form="regions_list"
            v-model="region_name"
            class="mx-3 my-1 block border-1 border-b-half border-light-app-purple w-full h-8 focus:outline-none placeholder-gray-500 text-lg"
            @change="callDidsByRegions()"
          >
            <option value="" disabled selected>Select Regions</option>
            <option
              v-for="(item, index) in RegionsInfo"
              :value="item"
              :key="index"
            >
              {{ item.region }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group pb-3">
        <div class="text-left mx-3 my-1">
          <label for="search_country" class="text-lg text-gray-500"
            >Search:</label
          >
        </div>
        <input
          type="text"
          id="search_country"
          name="search_country"
          placeholder="Type city name or prefix"
          class="mx-3 my-1 block border-1 border-light-app-purple w-full h-10 focus:outline-none placeholder-gray-500 text-lg"
        />
      </div>
    </div>
    <div class="w-1/5">
      <p class="text-lg my-2 text-left ml-4">Number types:</p>
      <hr class="mx-4" />
      <div class="flex flex-col items-start mx-6 mt-3">
        <div class="flex-1 flex flex-row items-center px-2 py-1">
          <input
            type="checkbox"
            id="num-local"
            name="num-local"
            class="bg-green-400"
            style="background: green"
          />
          <label for="num-local" class="text-lg ml-2">Local</label><br />
        </div>
        <div class="flex-1 flex flex-row items-center px-2 py-1">
          <input
            type="checkbox"
            id="num-national"
            name="num-national"
            class="bg-green-400"
            style="background: green"
          />
          <label for="num-national" class="text-lg ml-2"> National</label><br />
        </div>
        <div class="flex-1 flex flex-row items-center px-2 py-1">
          <input
            type="checkbox"
            id="num-mobile"
            name="num-mobile"
            class="bg-green-400"
            style="background: green"
          />
          <label for="num-mobile" class="text-lg ml-2"> Mobile</label><br />
        </div>
        <div class="flex-1 flex flex-row items-center px-2 py-1">
          <input
            type="checkbox"
            id="num-shared"
            name="num-shared"
            class="bg-green-400"
            style="background: green"
          />
          <label for="num-shared" class="text-lg ml-2"> Shared Cost</label
          ><br />
        </div>
        <div class="flex-1 flex flex-row items-center px-2 py-1">
          <input
            type="checkbox"
            id="num-toll-free"
            name="num-toll-free"
            class="bg-green-400"
            style="background: green"
          />
          <label for="num-toll-free" class="text-lg ml-2"> Toll-free</label
          ><br />
        </div>
        <div class="flex-1 flex flex-row items-center px-2 py-1">
          <input
            type="checkbox"
            id="num-global-uifn"
            name="num-global-uifn"
            class="bg-green-400"
            style="background: green"
          />
          <label for="num-global-uifn" class="text-lg ml-2">
            Global / UIFN</label
          ><br />
        </div>
      </div>
    </div>
    <div class="w-1/5">
      <p class="text-lg my-2 text-left ml-4">Biling Options:</p>
      <hr class="mx-4" />
      <div class="flex flex-col items-start mx-6 mt-3">
        <div class="flex-1 flex flex-row items-center px-2 py-1">
          <input
            type="checkbox"
            id="num-flat-rate"
            name="num-flat-rate"
            class="bg-green-400"
            style="background: green"
          />
          <label for="num-flat-rate" class="text-lg ml-2">Flat Rate</label
          ><br />
        </div>
        <div class="flex-1 flex flex-row items-center px-2 py-1">
          <input
            type="checkbox"
            id="num-metered"
            name="num-metered"
            class="bg-green-400"
            style="background: green"
          />
          <label for="num-metered" class="text-lg ml-2"> Metered</label><br />
        </div>
      </div>
    </div>
    <div class="w-1/5">
      <p class="text-lg my-2 text-left ml-4">Registration:</p>
      <hr class="mx-4" />
      <div class="flex flex-col items-start mx-6 mt-3">
        <div class="flex-1 flex flex-row items-center px-2 py-1">
          <input
            type="checkbox"
            id="num-required"
            name="num-required"
            class="bg-green-400"
            style="background: green"
          />
          <label for="num-required" class="text-lg ml-2">Required</label><br />
        </div>
        <div class="flex-1 flex flex-row items-center px-2 py-1">
          <input
            type="checkbox"
            id="num-not-required"
            name="num-not-required"
            class="bg-green-400"
            style="background: green"
          />
          <label for="num-not-required" class="text-lg ml-2">
            Not Required</label
          ><br />
        </div>
      </div>
    </div>
  </div>
  <div class="statistics-section m-5">
    <p class="text-left text-lg text-gray-600">Most Popular:</p>
    <hr classd="px-4 py-2" />
    <div class="popular-table">
      <table-lite
        :is-slot-mode="true"
        :is-static-mode="true"
        :is-loading="table.isLoading"
        :columns="table.columns"
        :rows="table.rows"
        :total="table.totalRecordCount"
        @is-finished="tableLoadingFinish"
      >
        <template v-slot:did_type="data">
          <div class="flex flex-row items-center justify-center">
            <div
              v-if="data.value.did_type[0] == true"
              class="bg-green-400 h-5 w-5 mx-2"
              style="border-radius: 20px"
            ></div>
            <div
              v-else
              class="bg-gray-200 h-5 w-5 mx-2"
              style="border-radius: 20px"
            ></div>
            <div
              v-if="data.value.did_type[1] == true"
              class="bg-green-400 h-5 w-5 mx-2"
              style="border-radius: 20px"
            ></div>
            <div
              v-else
              class="bg-gray-200 h-5 w-5 mx-2"
              style="border-radius: 20px"
            ></div>
            <div
              v-if="data.value.did_type[2] == true"
              class="bg-green-400 h-5 w-5 mx-2"
              style="border-radius: 20px"
            ></div>
            <div
              v-else
              class="bg-gray-200 h-5 w-5 mx-2"
              style="border-radius: 20px"
            ></div>
          </div>
        </template>
        <template v-slot:capacity="data">
          <div class="form-control">
            <select name="capacity" placeholder="Pay per minute" class="">
              <option
                v-for="(option, index) in data.value.capacity"
                :value="option"
                :key="index"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </template>
        <template v-slot:qty="data">
          <div class="">
            <input
              :value="data.value.qty"
              type="number"
              class="is-rows-el qty-input text-center"
              style="border: solid 1px lightgrey; width: 80%"
            />
          </div>
        </template>
        <template v-slot:cart="data">
          <div
            class="flex flex-row items-center justify-center m-1 p-1.5 bg-green-500 text-white cursor-pointer"
            style="border: solid 1px white; border-radius: 6px"
          >
            <font-awesome-icon icon="cart-plus" />
            <p class="ml-1">Add</p>
          </div>
        </template>
      </table-lite>
    </div>
  </div>
</template>

<script>
import TableLite from 'vue3-table-lite'
import { reactive, ref, computed, toRefs, watchEffect } from 'vue'
import useCoverageService from '@/modules/virtualnum/composables/DidwwService/useCoverageService'
export default {
  name: 'Coverage',
  components: {
    TableLite
  },
  setup (async) {
    const {
      fetchCountries,
      fetchCitiesByID,
      fetchRegionsByID,
      fetchAvailableDids
    } = useCoverageService()
    var countryInfo = ref([])
    var RegionsInfo = ref([])
    const country_name = ref('')
    const region_name = ref('')
    const citiesInfo = ref([])
    const available_dids = ref([])
    const visibleRegion = ref(false)
    const visibleCityList = ref(false)
    const callFetchCountries = async () => {
      let dataFilter = await fetchCountries()
      let testArray = Object.entries(dataFilter.data)
      return testArray
    }
    const callFetchRegions = async param_country_id => {
      let dataFilter = await fetchRegionsByID(param_country_id)
      return dataFilter
    }
    callFetchCountries().then(newitmes => {
      countryInfo.value = newitmes.map(function (el, index) {
        return { id: el[0], country: el[1].name }
      })
    })
    const callDidsByRegions = async () => {
      let checkRegionID = RegionsInfo.value.filter(function (value) {
        return value.region == region_name.value.region
      })
      if (checkRegionID.length > 0) {
        let didsFilter = await fetchAvailableDids(
          checkRegionID[0].id,
          'region_id'
        )
        setTableData(didsFilter.data)
      }
    }
    const callFetchCities = async () => {
      let dataFilter = await fetchCitiesByID(country_name.value.id)
      citiesInfo.value = dataFilter.data.map(function (el, index) {
        return { city: el.name }
      })
      let didsFilter = await fetchAvailableDids(
        country_name.value.id,
        'country_id'
      )
      available_dids.value = didsFilter.data.data.map(function (el, index) {
        return { id: el.id, number: el.attributes.number }
      })
      setTableData(didsFilter.data)
      if (country_name.value.id == '1f6fc2bd-f081-4202-9b1a-d9cb88d942b9') {
        visibleRegion.value = true
        callFetchRegions('1f6fc2bd-f081-4202-9b1a-d9cb88d942b9').then(
          newitmes => {
            RegionsInfo.value = newitmes.data.data.map(function (el, index) {
              return { id: el.id, region: el.attributes.name }
            })
          }
        )
      } else if (
        country_name.value.id == '24eb6e85-f628-4765-bbb2-420e48de76f2'
      ) {
        visibleRegion.value = true
        callFetchRegions('24eb6e85-f628-4765-bbb2-420e48de76f2').then(
          newitmes => {
            RegionsInfo.value = newitmes.data.data.map(function (el, index) {
              return { id: el.id, region: el.attributes.name }
            })
          }
        )
      } else {
        visibleRegion.value = false
      }
    }

    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: 'Prefix',
          field: 'prefix',
          width: '5%',
          sortable: true
        },
        {
          label: 'Name',
          field: 'name',
          width: '15%',
          sortable: true
        },
        {
          label: 'Registration',
          field: 'registration',
          width: '5%',
          sortable: true
        },
        {
          label: 'Voice | T3.8 | SMS',
          field: 'did_type',
          width: '10%'
        },
        {
          label: 'Capacity',
          field: 'capacity',
          width: '8%'
          // display: function (row) {
          //   return (
          //     '<input type="number" data-id="'+ row.itemname+'"class="is-rows-el qty-input" style="text-align: center; border: solid 1px lightgray; width: 160px;">' +
          //     '</input>'
          //   );
          // },
        },
        {
          label: 'Rate per min',
          field: 'rate',
          width: '9%'
        },
        {
          label: 'Setup',
          field: 'setup',
          width: '3%',
          sortable: true
          // display: function (row) {
          //   return (
          //     '<div class="subtotal" style="text-align: center; border: solid 1px lightgray; width: 160px;">' + row.activeprice * row.selfqty +
          //     '</div>'
          //   );
          // },
        },
        {
          label: 'Monthly',
          field: 'monthly',
          width: '3%',
          sortable: true
        },
        {
          label: 'Qty',
          field: 'qty',
          width: '8%'
        },
        {
          label: 'Cart',
          field: 'cart',
          width: '5%'
        }
      ],
      rows: [],
      searchResult: [],
      totalRecordCount: computed(() => {
        return table.rows.length
      })
      // sortable: {
      //   order: "itemname",
      //   sort: "asc",
      // },
    })
    const tableLoadingFinish = elements => {
      table.isLoading = false
      Array.prototype.forEach.call(elements, function (element) {
        if (element.classList.contains('qty-input')) {
          element.addEventListener('change', function () {
            //this.dataset.subtotal =  this.dataset.selfQTY + this.dataset.activeprice;
          })
        }
      })
    }
    const setTableData = param_table_data => {
      table.rows = []
      let didsAllInfo = []
      for (let item = 0; item < param_table_data.data.length; item++) {
        let did_item = param_table_data.included.filter(function (value) {
          return (
            value.id ==
            param_table_data.data[item]?.relationships?.did_group?.data?.id
          )
        })
        didsAllInfo.push(did_item)
      }
      for (var i = 0; i < didsAllInfo.length; i++) {
        let stock_unit_data = []
        for (var j = 0; j < param_table_data.included.length; j++) {
          let stock_unit_data_item = didsAllInfo[
            i
          ][0].relationships?.stock_keeping_units?.data.filter(function (
            value
          ) {
            return value.id == param_table_data.included[j].id
          })
          if (stock_unit_data_item.length > 0) {
            stock_unit_data.push(param_table_data.included[j])
          }
        }
        /**array arrange for features (voice_in, t38, voice_out)*/
        var arrayFeatures = [false, false, false]
        if (didsAllInfo[i][0].attributes?.features.includes('voice_in')) {
          arrayFeatures[0] = true
        }
        if (didsAllInfo[i][0].attributes?.features.includes('t38')) {
          arrayFeatures[1] = true
        }
        if (didsAllInfo[i][0].attributes?.features.includes('voice_out')) {
          arrayFeatures[2] = true
        }

        let tempObj = {
          prefix: didsAllInfo[i][0].attributes?.prefix,
          name: didsAllInfo[i][0].attributes?.area_name,
          registration:
            didsAllInfo[i][0].meta?.needs_registration == true
              ? 'Required'
              : 'Not Required',
          did_type: arrayFeatures,
          capacity: ['Pay per minute', '300 Dedicated'],
          rate: 'From $0.025',
          setup: stock_unit_data[0]?.attributes?.setup_price,
          monthly: stock_unit_data[0]?.attributes?.monthly_price,
          qty: 1,
          cart: ''
        }
        table.rows.push(tempObj)
      }
    }
    return {
      table,
      countryInfo,
      RegionsInfo,
      visibleRegion,
      country_name,
      region_name,
      citiesInfo,
      visibleCityList,
      callFetchCities,
      tableLoadingFinish,
      callDidsByRegions
    }
  },
  data () {
    return {
      testList: ['Canada', 'Germany', 'Japan']
    }
  },
  mounted () {
    // this.table.rows = [
    //     {
    //         prefix: '46-8',
    //         name: 'stockholm',
    //         registration: 'Not required',
    //         voice: '1,2,3',
    //         capacity: ['Pay per minute','2 Dedicated','Add later'],
    //         rate: 0.01,
    //         setup: 2.5,
    //         monthly: 2.5,
    //         qty:1,
    //         cart:''
    //     }
    // ]
  }
}
</script>

<style scoped>
.coverage-select {
  border-radius: 4px;
}
</style>
