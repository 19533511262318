<template>
    <div class="voicein-head m-1">
        <div class="top-section flex flex-row justify-between itmes-center">
            <div class="flex flex-row">
                <p class="text-2xl text-gray-600 p-4 text-left">SMS Trunk Profiles</p>
            </div>
            <div></div>
        </div>
        <hr class="mx-4">
        <div v-if="!noTrunks" class="flex flex-col items-center justify-center m-4 p-6 bg-gray-100">
            <div class="text-2xl text-gray-600 p-4 text-center">You have no SMS Trunks</div>
            <p class="my-1 cursor-pointer hover:text-gray-500">Click here for more information</p>
        </div>
        <div v-else class="m-4 p-6 bg-gray-100" style="border-radius: 10px;">
            <div class="search-section flex flex-row justify-start items-center m-2">
                <div class=""> <label class="text-lg mx-2">SearchBy:</label><input class="h-10" placeholder="Search" v-model="searchTerm" style="border:solid 1px lightgrey; border-radius: 4px;" /></div>
                <div class="form-group batch-action flex flex-row justify-start items-center m-2 p-2">
                    <select id="vInType" name="vInType" class="h-10" style="border:solid 1px lightgrey; border-radius: 4px;">
                        <option value="" disabled selected>Any Type</option>
                        <option  v-for="(item, index) in vInTypes" :value="item" :key="index">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
                <div class="flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;" @click.prevent="">
                    <font-awesome-icon icon="search" class="fa-fw fa-lg mx-1"/>
                    <p class="mx-1">Filter</p>
                </div>
            </div>
            <table-lite
                    :has-checkbox="true"
                    :is-slot-mode="true"
                    :is-static-mode="true"
                    :is-loading="table.isLoading"
                    :columns="table.columns"
                    :rows="table.rows"
                    :total="table.totalRecordCount"
                    @is-finished="tableLoadingFinish"
                    @return-checked-rows="updateCheckedRows"
            >
            </table-lite>
            <div class="form-group batch-action flex flex-row justify-start items-center m-4 p-4">
                <select id="batch-action" name="batch-action" class="h-10 w-44" style="border:solid 1px lightgrey; border-radius: 4px;">
                    <option value="" disabled selected>Select batch action</option>
                    <option  v-for="(item, index) in batchActions" :value="item" :key="index">
                        {{ item.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed, onMounted, reactive, ref } from 'vue'
    import TableLite from "vue3-table-lite";
    import vClickOutside from 'click-outside-vue3'

    export default {
        name: "SmsInput",
        components:{
            TableLite,
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        setup(){
            const noTrunks = ref(true);
            const searchTerm = ref("");
            const addNewList = ref(false);
            const hiddenListMenu = () =>{
                addNewList.value = false;
            }
            const showAddNewList = () => {
                addNewList.value = !addNewList.value;
            }
            const table = reactive({
                isLoading: false,
                columns: [
                    {
                        label: "Name",
                        field: "name",
                        width: "30%",
                        sortable: true
                    },
                    {
                        label: "Type",
                        field: "type",
                        width: "25%",
                        sortable: true
                    },
                    {
                        label: "Priority",
                        field: "priority",
                        width: "25%"
                    },
                    {
                        label: "Actions",
                        field: "actions",
                        width: "20%",
                    }
                ],
                rows: [],
                searchResult:[],
                totalRecordCount: computed(() => {
                    return table.rows.length;
                })
                // sortable: {
                //   order: "itemname",
                //   sort: "asc",
                // },
            });
            const tableLoadingFinish = (elements) => {
                table.isLoading = false;
                Array.prototype.forEach.call(elements, function (element) {
                    if (element.classList.contains("qty-input")) {
                        element.addEventListener("change", function () {
                            //this.dataset.subtotal =  this.dataset.selfQTY + this.dataset.activeprice;
                        });
                    }
                });
            };
            /** Row checked event */
            const updateCheckedRows = (rowsKey) => {};
            return{
                hiddenListMenu,
                addNewList,
                showAddNewList,
                noTrunks,
                tableLoadingFinish,
                table,
                updateCheckedRows,
                searchTerm
            }
        },
        data(){
            return{
                batchActions:[
                    {name : 'Remove trunks'}
                ],
                vInTypes:[
                    {name : 'E-mail'},
                    {name: 'HTTP'},
                    {name: 'SMPP ESME'},
                    {name : 'SMPP SMSC'},
                    {name: 'Trunk Group'}
                ]
            }
        },
        mounted(){
            this.table.rows = [
                {
                    name: 'SMS-TG',
                    type: 'HTTP',
                    priority: '1',
                    actions: 'actions'
                }
            ]
        },
        methods:{
        }
    }
</script>

<style scoped>

</style>
