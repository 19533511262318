import {computed, reactive, ref} from 'vue'
import axios from '@/services/axios'
import { useStore } from 'vuex'

export default function useCoverageService() {
    const store = useStore();
    const userEmail =  reactive(store.state.profilestore.personal_info);
    const fetchCountries = async () => {
        return await axios.get('api/virtualnum/countries', {params: {
                user_email: userEmail.personal_info.email
            }
        })
    }
    const fetchCitiesByID = async (param_country_id) =>{
        return await axios.get('api/virtualnum/cities', {params:{
                country_id: param_country_id
            }
        })
    }
    const fetchRegionsByID = async(param_country_id) =>{
        return await axios.get('api/virtualnum/regions',{params:{
            country_id: param_country_id
        }
        })
    }
    const fetchAvailableDids = async(set_param, filter_param) =>{
        if(filter_param == 'country_id'){
            return await axios.get('api/virtualnum/available_dids', {params:{ country_id: set_param}})
        }else if(filter_param == 'region_id'){
            return await axios.get('api/virtualnum/available_dids', {params:{ region_id: set_param}})
        }

    }

    return{
        fetchCountries,
        fetchCitiesByID,
        fetchRegionsByID,
        fetchAvailableDids
    }
}
