<template>
  <WidgetModal :show="show" @close="onClose">
    <div class="lg:max-w-883p w-full mx-auto h-full px-3">
      <div
        class="overflow-x-hidden"
        style="min-height: calc(100% - (1.75rem * 2))"
      >
        <div class="my-6">
          <div
            class="bg-white relative rounded-lg"
            style="border: solid 1px lightgrey"
          >
            <div
              class="absolute top-6 right-8 h-6 w-6 cursor-pointer"
              @click="onClose"
            >
              <img
                src="@/assets/images/close.svg"
                class="hover:opacity-50"
                alt=""
              />
            </div>
            <div class="py-6 px-4">
              <p class="text-xl">Create Trunk Group</p>
              <hr class="mt-3" />
            </div>
            <div class="px-4 pb-4">
              <div
                class="general_title h-8 bg-gray-100 flex flex-row justify-between items-center"
                style="
                  border: solid 1px lightgrey;
                  border-radius: 4px 4px 0px 0px;
                "
              >
                <p class="text-xl text-left py-1 px-3">General</p>
                <div class="py-1 px-3 cursor-pointer">
                  <font-awesome-icon
                    v-if="visibleGeneral"
                    icon="caret-up"
                    class="fa-fw fa-lg mx-1"
                    @click.prevent="visibleGeneral = false"
                  />
                  <font-awesome-icon
                    v-else
                    icon="caret-down"
                    class="fa-fw fa-lg mx-1"
                    @click.prevent="visibleGeneral = true"
                  />
                </div>
              </div>
              <div
                v-show="visibleGeneral"
                class=""
                style="
                  border: solid 1px lightgrey;
                  border-radius: 0px 0px 4px 4px;
                "
              >
                <div class="flex flex-row my-2 w-full">
                  <div class="w-2/5 flex flex-col justify-start p-2">
                    <label for="friendly_name" class="text-lg text-left">
                      Friendly Name
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="friendly_name"
                      name="friendly_name"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                  <div class="w-2/5 flex flex-col justify-start p-2">
                    <label for="prefered_server" class="text-lg text-left"
                      >Preferred Server
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <div
                      class="batch-action flex flex-row justify-start items-center"
                    >
                      <select
                        id="prefered_server"
                        name="prefered_server"
                        class="h-8 w-full"
                        style="border: solid 1px lightgrey; border-radius: 4px"
                      >
                        <option
                          v-for="(item, index) in preferred_server"
                          :value="item"
                          :key="index"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="w-1/5 flex flex-col justify-start p-2">
                    <label for="capacity_limit" class="text-lg text-left">
                      Capacity Limit
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="capacity_limit"
                      name="capacity_limit"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                </div>
                <div class="flex flex-row my-2 w-full">
                  <div class="w-2/5 flex flex-col justify-start p-2">
                    <label for="user_name" class="text-lg text-left">
                      Username
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="user_name"
                      name="user_name"
                      placeholder="{DID}"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                  <div class="w-2/5 flex flex-col justify-start p-2">
                    <div class="flex flex-row w-full">
                      <div
                        class="w-1/3 batch-action flex flex-col justify-start items-center"
                      >
                        <label for="general_transport" class="text-lg text-left"
                          >Transport
                          <font-awesome-icon icon="info-circle" />
                        </label>
                        <select
                          id="general_transport"
                          name="general_transport"
                          class="h-8 w-28"
                          style="
                            border: solid 1px lightgrey;
                            border-radius: 4px;
                          "
                        >
                          <option
                            v-for="(item, index) in transport"
                            :value="item"
                            :key="index"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                      <div class="w-2/3 flex flex-col justify-start pl-4">
                        <label for="general_host" class="text-lg text-left"
                          >Host
                          <font-awesome-icon icon="info-circle" />
                        </label>
                        <input
                          type="text"
                          id="general_host"
                          name="general_host"
                          placeholder="example.com"
                          class="border-2 border-lightgrey rounded-sm h-8"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w-1/5 flex flex-col justify-start p-2">
                    <label for="general_port" class="text-lg text-left">
                      Port
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="general_port"
                      name="general_port"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                </div>
                <div class="flex flex-row justify-start my-4">
                  <div class="flex flex-row items-center px-2 py-1">
                    <input
                      type="checkbox"
                      id="general_dns_srv_record"
                      name="general_dns_srv_record"
                      class=""
                      style=""
                    />
                    <label for="general_dns_srv_record" class="text-lg ml-2"
                      >Resolve DNS SRV record</label
                    >
                    <font-awesome-icon icon="info-circle" class="ml-2" />
                  </div>
                  <div class="flex flex-row items-center px-2 py-1 ml-4">
                    <input
                      type="checkbox"
                      id="general_resolve_rURI"
                      name="general_resolve_rURI"
                      class=""
                      style=""
                    />
                    <label for="general_resolve_rURI" class="text-lg ml-2"
                      >Resolve rURI</label
                    >
                    <font-awesome-icon icon="info-circle" class="ml-2" />
                  </div>
                </div>
                <div class="flex flex-row my-2 w-full">
                  <div class="w-2/5 flex flex-col justify-start p-2">
                    <label for="general_cli_format" class="text-lg text-left"
                      >CLI Format
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <div
                      class="batch-action flex flex-row justify-start items-center"
                    >
                      <select
                        id="general_cli_format"
                        name="general_cli_format"
                        class="h-8 w-full"
                        style="border: solid 1px lightgrey; border-radius: 4px"
                      >
                        <option
                          v-for="(item, index) in CLI_Format"
                          :value="item"
                          :key="index"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="w-2/5 flex flex-col justify-start p-2">
                    <label for="general_cli_prefix" class="text-lg text-left"
                      >CLI Prefix
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="general_cli_prefix"
                      name="general_cli_prefix"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                </div>
                <div class="flex flex-row justify-start my-4">
                  <div class="flex flex-row items-center px-2 py-1">
                    <input
                      type="checkbox"
                      id="general_map_all_dids"
                      name="general_map_all_dids"
                      class=""
                      style=""
                    />
                    <label for="general_map_all_dids" class="text-lg ml-2"
                      >Map all DID(s)</label
                    >
                    <font-awesome-icon icon="info-circle" class="ml-2" />
                  </div>
                </div>
              </div>
            </div>
            <div class="px-4 pb-4">
              <div
                class="general_title h-8 bg-gray-100 flex flex-row justify-between items-center"
                style="
                  border: solid 1px lightgrey;
                  border-radius: 4px 4px 0px 0px;
                "
              >
                <p class="text-xl text-left py-1 px-3">
                  Trunk Group Configuration
                </p>
                <div class="py-1 px-3 cursor-pointer">
                  <font-awesome-icon
                    v-if="visibleGroupConfig"
                    icon="caret-up"
                    class="fa-fw fa-lg mx-1"
                    @click.prevent="visibleGroupConfig = false"
                  />
                  <font-awesome-icon
                    v-else
                    icon="caret-down"
                    class="fa-fw fa-lg mx-1"
                    @click.prevent="visibleGroupConfig = true"
                  />
                </div>
              </div>
              <div
                v-show="visibleGroupConfig"
                class=""
                style="
                  border: solid 1px lightgrey;
                  border-radius: 0px 0px 4px 4px;
                "
              >
                <div class="flex flex-row items-center my-2 mx-1 w-full">
                  <p class="text-left ml-2 my-1">Assign to trunk group</p>
                  <font-awesome-icon icon="info-circle" class="ml-2" />
                </div>
                <div class="px-2">
                  <div
                    class="general_title h-7 bg-gray-100 flex flex-row justify-between items-center"
                    style="
                      border: solid 1px lightgrey;
                      border-radius: 4px 4px 0px 0px;
                    "
                  >
                    <p class="text-lg text-left py-1 px-3">
                      Select Trunk Group
                    </p>
                    <div class="py-1 px-3 cursor-pointer">
                      <font-awesome-icon
                        v-if="visibleTrunkGroup"
                        icon="angle-up"
                        class="fa-fw fa-lg mx-1"
                        @click.prevent="visibleTrunkGroup = false"
                      />
                      <font-awesome-icon
                        v-else
                        icon="angle-down"
                        class="fa-fw fa-lg mx-1"
                        @click.prevent="visibleTrunkGroup = true"
                      />
                    </div>
                  </div>
                  <div
                    v-show="visibleTrunkGroup"
                    class=""
                    style="
                      border: solid 1px lightgrey;
                      border-radius: 0px 0px 4px 4px;
                    "
                  >
                    <ul>
                      <li
                        class="px-2 text-left hover:bg-gray-200 cursor-pointer"
                        v-for="(content, index) in selectTrunkGroup"
                        :value="content"
                        :key="index"
                      >
                        {{ content.name }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="flex flex-row my-2 w-full">
                  <div class="w-1/3 flex flex-col justify-start p-2">
                    <label for="trunk_group_priority" class="text-lg text-left">
                      Priority
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="trunk_group_priority"
                      name="trunk_group_priority"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                  <div class="w-1/3 flex flex-col justify-start p-2">
                    <label for="trunk_group_weight" class="text-lg text-left">
                      Weight
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="trunk_group_weight"
                      name="trunk_group_weight"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                  <div class="w-1/3 flex flex-col justify-start p-2">
                    <label
                      for="group_ringing_timeout"
                      class="text-lg text-left"
                    >
                      Ringing TimeOut
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="group_ringing_timeout"
                      name="group_ringing_timeout"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                </div>
                <div class="flex flex-row w-full my-2">
                  <div class="w-2/5 flex flex-col">
                    <div class="flex flex-col justify-start p-2">
                      <label class="text-sm text-left mb-1">
                        Available Re-routing Disconnect Codes
                        <font-awesome-icon icon="info-circle" />
                      </label>
                      <div class="pnl-scroll w-full h-54 bg-gray-200">
                        <ul class="my-1 mx-1">
                          <li
                            class="px-2 text-left hover:bg-white cursor-pointer"
                            v-for="(content, index) in ValidReRoutingCodes"
                            :value="content"
                            :key="index"
                          >
                            {{ content.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="w-1/5 flex flex-row justify-center items-center px-5"
                  >
                    <div
                      class="border-1 p-2 text-sm cursor-pointer hover:bg-gray-200"
                      style="border: solid 1px lightgrey; border-radius: 4px"
                    >
                      &lt;&lt;
                    </div>
                    <div
                      class="border-1 p-2 text-sm cursor-pointer hover:bg-gray-200"
                      style="border: solid 1px lightgrey; border-radius: 4px"
                    >
                      >>
                    </div>
                  </div>
                  <div class="w-2/5 flex flex-col">
                    <div class="flex flex-col justify-start p-2">
                      <label class="text-sm text-left mb-1">
                        Re-routing Disconnect Codes
                        <font-awesome-icon icon="info-circle" />
                      </label>
                      <div class="pnl-scroll w-full h-54 bg-gray-200">
                        <ul class="my-1 mx-1">
                          <li
                            class="px-2 text-left hover:bg-white cursor-pointer"
                            v-for="(content, index) in ReRoutingCodes"
                            :value="content"
                            :key="index"
                          >
                            {{ content.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row w-full my-2 items-center mx-2">
                  <input
                    type="checkbox"
                    id="disconnect_rule"
                    name="disconnect_rule"
                  />
                  <label for="disconnect_rule" class="text-md ml-2"
                    >Use default Re-routing Disconnect rules
                    <font-awesome-icon icon="info-circle" />
                  </label>
                </div>
              </div>
            </div>
            <div class="px-4 pb-4">
              <div
                class="general_title h-8 bg-gray-100 flex flex-row justify-between items-center"
                style="
                  border: solid 1px lightgrey;
                  border-radius: 4px 4px 0px 0px;
                "
              >
                <p class="text-xl text-left py-1 px-3">Authentication</p>
                <div class="py-1 px-3 cursor-pointer">
                  <font-awesome-icon
                    v-if="visibleAuth"
                    icon="caret-up"
                    class="fa-fw fa-lg mx-1"
                    @click.prevent="visibleAuth = false"
                  />
                  <font-awesome-icon
                    v-else
                    icon="caret-down"
                    class="fa-fw fa-lg mx-1"
                    @click.prevent="visibleAuth = true"
                  />
                </div>
              </div>
              <div
                v-show="visibleAuth"
                class=""
                style="
                  border: solid 1px lightgrey;
                  border-radius: 0px 0px 4px 4px;
                "
              >
                <div class="flex flex-row items-center my-2 ml-3 w-full">
                  <input
                    type="checkbox"
                    id="auth_enabled"
                    name="auth_enabled"
                    class=""
                    style=""
                  />
                  <label class="text-left ml-2 my-1" for="auth_enabled"
                    >Auth enabled</label
                  >
                  <font-awesome-icon icon="info-circle" class="ml-2" />
                </div>
                <div class="flex flex-row my-2 w-full">
                  <div class="w-1/2 flex flex-col justify-start px-2 py-1">
                    <label for="auth_user" class="text-lg text-left">
                      Auth user
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="auth_user"
                      name="auth_user"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                  <div class="w-1/2 flex flex-col justify-start px-2 py-1">
                    <label for="auth_pwd" class="text-lg text-left">
                      Auth password
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="auth_pwd"
                      name="auth_pwd"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                </div>
                <div class="flex flex-row my-2 w-full">
                  <div class="w-1/2 flex flex-col justify-start px-2 py-1">
                    <label for="auth_from_user" class="text-lg text-left">
                      Auth "From" User
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="auth_from_user"
                      name="auth_from_user"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                  <div class="w-1/2 flex flex-col justify-start px-2 py-1">
                    <label for="auth_from_domain" class="text-lg text-left">
                      Auth "From" Domain
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="auth_from_domain"
                      name="auth_from_domain"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="px-4 pb-4">
              <div
                class="general_title h-8 bg-gray-100 flex flex-row justify-between items-center"
                style="
                  border: solid 1px lightgrey;
                  border-radius: 4px 4px 0px 0px;
                "
              >
                <p class="text-xl text-left py-1 px-3">Media & DTMF</p>
                <div class="py-1 px-3 cursor-pointer">
                  <font-awesome-icon
                    v-if="visibleMediaDTMF"
                    icon="caret-up"
                    class="fa-fw fa-lg mx-1"
                    @click.prevent="visibleMediaDTMF = false"
                  />
                  <font-awesome-icon
                    v-else
                    icon="caret-down"
                    class="fa-fw fa-lg mx-1"
                    @click.prevent="visibleMediaDTMF = true"
                  />
                </div>
              </div>
              <div
                v-show="visibleMediaDTMF"
                class=""
                style="
                  border: solid 1px lightgrey;
                  border-radius: 0px 0px 4px 4px;
                "
              >
                <div class="flex flex-row w-full my-2">
                  <div class="w-2/5 flex flex-col">
                    <div class="flex flex-col justify-start p-2">
                      <label class="text-sm text-left mb-1">
                        Available codecs
                        <font-awesome-icon icon="info-circle" />
                      </label>
                      <div class="pnl-scroll w-full h-54 bg-gray-200">
                        <ul class="my-1 mx-1">
                          <li
                            class="px-2 text-left hover:bg-white cursor-pointer"
                            v-for="(content, index) in availableCodecs"
                            :value="content"
                            :key="index"
                          >
                            {{ content.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="w-1/5 flex flex-row justify-center items-center px-5"
                  >
                    <div class="flex-1 flex flex-row">
                      <div
                        class="border-1 p-1 text-sm cursor-pointer hover:bg-gray-200"
                        style="border: solid 1px lightgrey; border-radius: 4px"
                      >
                        &lt;&lt;
                      </div>
                      <div
                        class="border-1 p-1 text-sm cursor-pointer hover:bg-gray-200"
                        style="border: solid 1px lightgrey; border-radius: 4px"
                      >
                        >>
                      </div>
                    </div>
                    <div
                      class="flex-1 flex flex-col justify-center items-center"
                    >
                      <div
                        class="border-1 w-1/2 p-1 text-sm cursor-pointer hover:bg-gray-200"
                        style="
                          border: solid 1px lightgrey;
                          border-radius: 4px;
                          transform: rotate(90deg);
                        "
                      >
                        &lt;
                      </div>
                      <div
                        class="border-1 w-1/2 p-1 text-sm cursor-pointer hover:bg-gray-200"
                        style="
                          border: solid 1px lightgrey;
                          border-radius: 4px;
                          transform: rotate(-90deg);
                        "
                      >
                        &lt;
                      </div>
                    </div>
                  </div>
                  <div class="w-2/5 flex flex-col">
                    <div class="flex flex-col justify-start p-2">
                      <label class="text-sm text-left mb-1">
                        Selected codecs
                        <font-awesome-icon icon="info-circle" />
                      </label>
                      <div class="pnl-scroll w-full h-54 bg-gray-200">
                        <ul class="my-1 mx-1">
                          <li
                            class="px-2 text-left hover:bg-white cursor-pointer"
                            v-for="(content, index) in selcetedCodecs"
                            :value="content"
                            :key="index"
                          >
                            {{ content.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row my-2 w-full">
                  <div class="w-1/3 flex flex-col justify-start p-2">
                    <label for="customer_dtmf_format" class="text-lg text-left"
                      >Preferred Server
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <div
                      class="batch-action flex flex-row justify-start items-center"
                    >
                      <select
                        id="customer_dtmf_format"
                        name="customer_dtmf_format"
                        class="h-8 w-full"
                        style="border: solid 1px lightgrey; border-radius: 4px"
                      >
                        <option
                          v-for="(item, index) in customer_dtmf_format"
                          :value="item"
                          :key="index"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="w-1/3 flex flex-col justify-start p-2">
                    <label for="didww_dtmf_format" class="text-lg text-left"
                      >Preferred Server
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <div
                      class="batch-action flex flex-row justify-start items-center"
                    >
                      <select
                        id="didww_dtmf_format"
                        name="didww_dtmf_format"
                        class="h-8 w-full"
                        style="border: solid 1px lightgrey; border-radius: 4px"
                      >
                        <option
                          v-for="(item, index) in didww_dtmf_format"
                          :value="item"
                          :key="index"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="w-1/3 flex flex-col justify-start p-2">
                    <label for="rtp_timeout" class="text-lg text-left">
                      RTP Timeout
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="rtp_timeout"
                      name="rtp_timeout"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                </div>
                <div class="flex flex-row my-2 w-full">
                  <div class="w-1/3 flex flex-col justify-start p-2">
                    <label for="encryption_mode" class="text-lg text-left"
                      >Encryption Mode
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <div
                      class="batch-action flex flex-row justify-start items-center"
                    >
                      <select
                        id="encryption_mode"
                        name="encryption_mode"
                        class="h-8 w-full"
                        style="border: solid 1px lightgrey; border-radius: 4px"
                      >
                        <option
                          v-for="(item, index) in encryption_mode"
                          :value="item"
                          :key="index"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="w-1/3 flex flex-col justify-start p-2">
                    <label
                      for="allowed_rtp_ip_addresses"
                      class="text-lg text-left"
                    >
                      Allowed RTP IP addresses
                      <font-awesome-icon icon="info-circle" />
                    </label>
                    <input
                      type="text"
                      id="allowed_rtp_ip_addresses"
                      name="allowed_rtp_ip_addresses"
                      class="border-2 border-lightgrey rounded-sm h-8"
                    />
                  </div>
                  <div class="w-1/3 flex flex-col justify-start p-2"></div>
                </div>
                <div class="flex flex-row my-2 w-full">
                  <div
                    class="w-1/3 flex flex-row justify-start items-center p-2"
                  >
                    <input
                      type="checkbox"
                      id="rtp_ping"
                      name="rtp_ping"
                      class=""
                      style=""
                    />
                    <label for="rtp_ping" class="text-sm ml-2">RTP Ping</label>
                    <font-awesome-icon icon="info-circle" class="ml-2" />
                  </div>
                  <div
                    class="w-1/3 flex flex-row justify-start items-center p-2"
                  >
                    <input
                      type="checkbox"
                      id="force_symmetric_rtp"
                      name="force_symmetric_rtp"
                      class=""
                      style=""
                    />
                    <label for="force_symmetric_rtp" class="text-sm ml-2"
                      >Force Symmetric RTP</label
                    >
                    <font-awesome-icon icon="info-circle" class="ml-2" />
                  </div>
                  <div
                    class="w-1/3 flex flex-row justify-start items-center p-2"
                  >
                    <input
                      type="checkbox"
                      id="symmetric_ignore_rtcp"
                      name="symmetric_ignore_rtcp"
                      class=""
                      style=""
                    />
                    <label for="symmetric_ignore_rtcp" class="text-sm ml-2"
                      >Symmetric RTP Ignore RTCP</label
                    >
                    <font-awesome-icon icon="info-circle" class="ml-2" />
                  </div>
                </div>
              </div>
            </div>
            <hr class="m-2" />
            <div class="px-4 pb-4 flex flex-row justify-between items-center">
              <div
                class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500"
                style="border-radius: 6px"
                @click.prevent="onClose"
              >
                <p class="mx-2">Close</p>
              </div>
              <div
                class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500"
                style="border-radius: 6px"
                @click.prevent="submitData"
              >
                <font-awesome-icon icon="check" class="fa-fw fa-lg mx-1" />
                <p class="ml-1">Submit</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WidgetModal>
</template>

<script>
import { ref, computed } from "vue";
import WidgetModal from "@/shared/WidgetModal";
export default {
  name: "CreateSiptrunk",
  emits: ["close"],
  components: {
    WidgetModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const visibleGeneral = ref(true);
    const visibleGroupConfig = ref(false);
    const visibleTrunkGroup = ref(false);
    const visibleAuth = ref(false);
    const visibleMediaDTMF = ref(false);
    const onClose = () => {
      emit("close", "close");
    };
    const submitData = () => {};
    return {
      onClose,
      submitData,
      visibleGeneral,
      visibleGroupConfig,
      visibleTrunkGroup,
      visibleAuth,
      visibleMediaDTMF,
    };
  },
  data() {
    return {
      preferred_server: [
        { name: "Local" },
        { name: "DE, FRA" },
        { name: "SG" },
        { name: "US, LA" },
        { name: "US, MIA" },
        { name: "US, NY" },
      ],
      transport: [{ name: "UDP" }, { name: "TCP" }, { name: "TLS" }],
      CLI_Format: [{ name: "raw" }, { name: "e164" }, { name: "local" }],
      selectTrunkGroup: [{ name: "TestGroupOne" }, { name: "TestGroupTwo" }],
      ValidReRoutingCodes: [
        { name: "401 | Unauthorized" },
        { name: "503 | Service Unavailable" },
      ],
      ReRoutingCodes: [
        { name: "400 | Bad Request" },
        { name: "402 | Payment Required" },
        { name: "403 | Forbidden" },
        { name: "404 | Not Found" },
        { name: "408 | Request Timeout" },
        { name: "409 | Conflict" },
        { name: "410 | Gone" },
        { name: "412 | Conditional Request Failed" },
        { name: "413 | Request Entity Too Large" },
        { name: "414 | Request-URI Too Long" },
        { name: "415 | Unsupported Media Type" },
      ],
      availableCodecs: [
        { name: "speex" },
        { name: "GSM" },
        { name: "G726-32" },
        { name: "G721" },
        { name: "G726-24" },
        { name: "G726-40" },
        { name: "G726-16" },
        { name: "L16" },
      ],
      selcetedCodecs: [
        { name: "PCMU" },
        { name: "PCMA" },
        { name: "G729" },
        { name: "G723" },
        { name: "telephone-event" },
      ],
      customer_dtmf_format: [
        { name: "RFC 2833" },
        { name: "SIP INFO application/dtmf-relay OR application/dtmf" },
        { name: "RFC 2833 OR SIP INFO" },
      ],
      didww_dtmf_format: [
        { name: "Disable sending" },
        { name: "RFC 2833" },
        { name: "SIP INFO application/dtmf-relay" },
        { name: "SIP INFO application/dtmf" },
      ],
      encryption_mode: [
        { name: "Disabled" },
        { name: "SRTP SDES" },
        { name: "SRTP DTLS" },
        { name: "SRTP ZRTP" },
      ],
    };
  },
};
</script>

<style scoped>
.pnl-scroll {
  border-radius: 4px;
  height: 230px;
  overflow-y: scroll;
}
</style>
