<template>
    <div class="voiceout-head m-1">
        <div class="top-section flex flex-row justify-between itmes-center">
            <div class="flex flex-row">
                <p class="text-2xl text-gray-600 p-4 text-left">Voice Trunks profiles</p>
            </div>
            <div class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;" @click.prevent="setOutBoundVisible">
                <font-awesome-icon icon="plus" class="fa-fw fa-lg mx-1"/>
                <p class="ml-1">Add new</p>
            </div>
        </div>
        <hr class="mx-4">
        <div v-if="!noTrunks" class="flex flex-col items-center justify-center m-4 p-6 bg-gray-100">
            <div class="text-2xl text-gray-600 p-4 text-center">You have no trunks</div>
            <div class="flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;" @click.prevent="">
                <font-awesome-icon icon="plus" class="fa-fw fa-lg mx-1"/>
                <p class="mx-1">Add new</p>
            </div>
        </div>
        <div v-else class="m-4 p-6 bg-gray-100" style="border-radius: 10px;">
            <div class="search-section flex flex-row justify-start items-center m-2">
                <div class=""> <label class="text-lg mx-2">SearchBy:</label><input class="h-10" placeholder="Name or UserName" v-model="searchTerm" style="border:solid 1px lightgrey; border-radius: 4px;" /></div>
                <div class="flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;" @click.prevent="">
                    <font-awesome-icon icon="search" class="fa-fw fa-lg mx-1"/>
                    <p class="mx-1">Filter</p>
                </div>
            </div>
            <table-lite
                    :has-checkbox="true"
                    :is-slot-mode="true"
                    :is-static-mode="true"
                    :is-loading="table.isLoading"
                    :columns="table.columns"
                    :rows="table.rows"
                    :total="table.totalRecordCount"
                    @is-finished="tableLoadingFinish"
                    @return-checked-rows="updateCheckedRows"
            >

            </table-lite>
            <div class="form-group batch-action flex flex-row justify-start items-center m-4 p-4">
                <select id="batch-action" name="batch-action" class="h-10 w-44" style="border:solid 1px lightgrey; border-radius: 4px;">
                    <option value="" disabled selected>Select batch action</option>
                    <option  v-for="(item, index) in batchActions" :value="item" :key="index">
                        {{ item.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <CreateOutbound :show="createOutBoundVisible" @close="createOutBoundVisible=false"></CreateOutbound>
</template>

<script>
    import {computed, onMounted, reactive, ref} from 'vue'
    import TableLite from "vue3-table-lite";
    import CreateOutbound from '@/modules/virtualnum/components/home/Modals/CreateOutbound'

    export default {
        name: "VoiceOutput",
        components:{
            TableLite,
            CreateOutbound
        },
        setup(){
            const noTrunks = ref(true);
            const searchTerm = ref("");
            const createOutBoundVisible = ref(false);
            const setOutBoundVisible = () => {
                createOutBoundVisible.value = true
            }
            const table = reactive({
                isLoading: false,
                columns: [
                    {
                        label: "Name",
                        field: "name",
                        width: "15%",
                        sortable: true
                    },
                    {
                        label: "Credentials",
                        field: "credentials",
                        width: "10%",
                        sortable: true
                    },
                    {
                        label: "Allowed SIP IPs",
                        field: "ips",
                        width: "10%"
                    },
                    {
                        label: "24 hour limit(USD)",
                        field: "hourlimit",
                        width: "10%",
                    },
                    {
                        label: "Status",
                        field: "status",
                        width: "8%",
                    },
                    {
                        label: "Capacity limit",
                        field: "capacitylimit",
                        width: "12%",
                    },
                    {
                        label: "Actions",
                        field: "actions",
                        width: "10%"
                    }
                ],
                rows: [],
                searchResult:[],
                totalRecordCount: computed(() => {
                    return table.rows.length;
                })
            });
            const tableLoadingFinish = (elements) => {
                table.isLoading = false;
            };
            /** Row checked event */
            const updateCheckedRows = (rowsKey) => {};
            return{
                createOutBoundVisible,
                setOutBoundVisible,
                noTrunks,
                tableLoadingFinish,
                table,
                updateCheckedRows,
                searchTerm
            }
        },
        data(){
            return{
                batchActions:[
                    {name : 'Remove trunks'}
                ]
            }
        },
        mounted(){
            this.table.rows = [
                {
                    name: 'Trunk OUT-1',
                    credentials: '********',
                    ips: '54.93.80.94/32',
                    hourlimit: 100,
                    status: true,
                    capacitylimit: 100,
                    actions:'actions'
                }
            ]
        }

    }
</script>

<style scoped>

</style>
