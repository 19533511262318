<template>
    <div class="userdids-head m-1">
        <div class="top-section flex flex-row justify-between itmes-center">
            <div class="flex flex-row">
                <p class="text-2xl text-gray-600 p-4 text-left">My DID Numbers</p>
                <div v-show="didsCount" class="flex flex-row items-center justify-center">
                    <div>:</div>
                    <font-awesome-icon icon="user" class="fa-fw fa-lg mx-1"/>
                    <p class="mx-1 text-xl">Active</p>
                    <div class="bg-green-400 w-5 h-5 mb-3" style="border-radius: 12px">1</div>
                </div>
            </div>
            <div class="btn-order-new bg-app-purple m-1 p-1.5 text-white cursor-pointer" style="" @click.prevent="$router.push({ name: 'virtual-dashboard' })">
                <p class="ml-1">Order New DIDS</p>
            </div>
        </div>
        <hr class="mx-4">
        <div v-if="!didsCount" class="flex flex-col items-center justify-center m-4 p-6 bg-gray-100">
            <div class="text-2xl text-gray-600 p-4 text-center">You have no DIDs.</div>
            <div class="btn-order-new bg-app-purple m-1 p-1.5 text-white cursor-pointer" style="" @click.prevent="$router.push({ name: 'virtual-dashboard' })">
                <p class="ml-1">Order New DIDS</p>
            </div>
        </div>
        <div v-else class="m-4 p-6 bg-gray-100" style="border-radius: 10px;">
            <table-lite
                    :has-checkbox="true"
                    :is-slot-mode="true"
                    :is-static-mode="true"
                    :is-loading="table.isLoading"
                    :columns="table.columns"
                    :rows="table.rows"
                    :total="table.totalRecordCount"
                    @is-finished="tableLoadingFinish"
                    @return-checked-rows="updateCheckedRows"
            >

            </table-lite>
            <div class="form-group batch-action flex flex-row justify-start items-center m-4 p-4">
                <select id="batch-action" name="batch-action" class="h-10">
                    <option value="" disabled selected>Select batch action</option>
                    <option  v-for="(item, index) in batchActions" :value="item" :key="index">
                        {{ item.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed, onMounted, reactive, ref} from 'vue'
    import TableLite from "vue3-table-lite";
    export default {
        name: "Userdids",
        components:{
            TableLite
        },
        setup(){
            const didsCount = ref(true);
            const table = reactive({
                isLoading: false,
                columns: [
                    {
                        label: "Country / City",
                        field: "country",
                        width: "10%",
                        sortable: true
                    },
                    {
                        label: "DID Number",
                        field: "didnum",
                        width: "15%",
                        sortable: true
                    },
                    {
                        label: "Status / Time left",
                        field: "status",
                        width: "10%",
                        sortable: true
                    },
                    {
                        label: "Voice | T3.8 | SMS",
                        field: "voice",
                        width: "10%",
                    },
                    {
                        label: "Trunk",
                        field: "trunk",
                        width: "15%",
                    },
                    {
                        label: "Capacity",
                        field: "capacity",
                        width: "8%",
                    },
                    {
                        label: "Actions",
                        field: "actions",
                        width: "10%",
                        sortable: true
                    }
                ],
                rows: [],
                searchResult:[],
                totalRecordCount: computed(() => {
                    return table.rows.length;
                })
                // sortable: {
                //   order: "itemname",
                //   sort: "asc",
                // },
            });
            const tableLoadingFinish = (elements) => {
                table.isLoading = false;
                Array.prototype.forEach.call(elements, function (element) {
                    if (element.classList.contains("qty-input")) {
                        element.addEventListener("change", function () {
                            //this.dataset.subtotal =  this.dataset.selfQTY + this.dataset.activeprice;
                        });
                    }
                });
            };
            /**
             * Row checked event
             */
            const updateCheckedRows = (rowsKey) => {};
            return{
                didsCount,
                tableLoadingFinish,
                table,
                updateCheckedRows
            }
        },
        data(){
            return{
                batchActions:[
                    {name : 'Renew DIDs'},
                    {name: 'Set number of Billing Cycles'},
                    {name: 'Update trunks'}
                ]
            }
        },
        mounted(){
            this.table.rows = [
                {
                    country: 'United Kingdom',
                    didnum: '+ 44(7) 520640814',
                    status: 29,
                    voice: [1,2,1],
                    trunk: ['Voice','Xlinx UK WITH RPEFIX'],
                    capacity: [2,1],
                    actions:'actions'
                }
            ]
        }
    }
</script>

<style scoped>
.btn-order-new{
    border: 1px solid lightgrey;
    border-radius: 6px;
    height: 40px;
    margin: 0 10px;
}
.btn-order-new:hover{
    color: lightgrey;
}

</style>
