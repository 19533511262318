import axios from "axios";
// import useToaster from '@/composables/useToaster'
import authenticate from "@/composables/auth/authenticate";
import validator from "@/composables/auth/validator";

const { logout } = authenticate();
const { fillErrors } = validator();

const instance = axios.create({
  baseURL: process.env.API_URL || "http://127.0.0.1:8000/",
  headers: {
    accept: "application/json",
  },
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  if (config.method === "post") {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "pkce_code_verifier"
    )}`;
  }
  // Modify or add to the config object here

  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if ([401, 403, 419].includes(error.status)) {
      logout();
      return;
    } else {
      if (error.response.data.errors) {
        fillErrors(error.response.data.errors);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
