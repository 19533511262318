<template>
    <div class="topnav">
        <ul>
            <li class="flex flex-col itmes-center justify-center" :class="{'active': isCapaExtended}" @click="setCurrentTab(0)">
                <font-awesome-icon icon="box" class="mb-1" />
                <p>Extended</p>
            </li>
            <li class="flex flex-col itmes-center justify-center" :class="{'active': isCapaStandard}" @click="setCurrentTab(1)">
                <font-awesome-icon icon="inbox" class="mb-1"/>
                <p>Standard</p>
            </li>
        </ul>
    </div>
    <div v-if="currentTab == 0" class="body-section">
        <CapaExtended />
    </div>
    <div v-else-if="currentTab == 1" class="body-section">
        <CapaStandard />
    </div>
    <div v-else></div>
</template>

<script>
    import {computed, onMounted, reactive, ref} from 'vue'
    import CapaExtended from "./Capacity/CapaExtended";
    import CapaStandard from "./Capacity/CapaStandard";
    export default {
        name: "Capacity",
        components:{
            CapaExtended,
            CapaStandard
        },
        setup() {
            const currentTab = ref(0);
            const isCapaExtended = ref(true);
            const isCapaStandard = ref(false);
            const setCurrentTab = (value) => {
                currentTab.value = value;
                isCapaExtended.value = false;
                isCapaStandard.value = false;
                if (value == 0) {
                    isCapaExtended.value = true;
                } else if (value == 1) {
                    isCapaStandard.value = true;
                } else {
                }
            }

            return {
                currentTab,
                setCurrentTab,
                isCapaExtended,
                isCapaStandard
            }
        }
    }
</script>

<style scoped>
    .topnav {
        --tw-bg-opacity: 1;
        background-color: rgba(155, 155, 197, var(--tw-bg-opacity));
        overflow: hidden;
        text-align: left;
    }

    .topnav li {
        float: left;
        color: #f2f2f2;
        text-align: center;
        padding: 16px 18px;
        text-decoration: none;
        font-size: 17px;
        cursor: pointer;
        width:115px;
        height:90px;
    }

    .topnav li:hover {
        background-color: #ddd;
        color: black;
    }

    .topnav li.active {
        background-color: lightgrey;
        color: black;
    }
</style>
