<template>
    <WidgetModal :show="show" @close="onClose">
        <div class="lg:max-w-883p w-full mx-auto h-full px-3">
            <div class="overflow-x-hidden" style="min-height: calc(100% - (1.75rem * 2))">
                <div class="my-6">
                    <div class="bg-white relative rounded-lg" style="border: solid 1px lightgrey;">
                        <div class="absolute top-6 right-8 h-6 w-6 cursor-pointer" @click="onClose">
                            <img src="@/assets/images/close.svg" class="hover:opacity-50" alt=""/>
                        </div>
                        <div class="py-6 px-4">
                            <p class="text-xl">Create OutBound Trunk</p>
                            <hr class="mt-3">
                        </div>
                        <div class="px-4 pb-4">
                            <div class="general_title h-8 bg-gray-100 flex flex-row justify-between items-center" style="border: solid 1px lightgrey; border-radius: 4px 4px 0px 0px;">
                                <p class="text-xl text-left py-1 px-3">Authentication</p>
                                <div class="py-1 px-3 cursor-pointer">
                                    <font-awesome-icon v-if="visibleGeneral" icon="caret-up" class="fa-fw fa-lg mx-1" @click.prevent="visibleGeneral = false"/>
                                    <font-awesome-icon v-else icon="caret-down" class="fa-fw fa-lg mx-1" @click.prevent="visibleGeneral = true"/>
                                </div>
                            </div>
                            <div v-show="visibleGeneral" class="" style="border: solid 1px lightgrey; border-radius:0px 0px 4px 4px;">
                                <div class="flex flex-row my-2 mx-1">
                                    <div class="flex-1 flex flex-col justify-start px-3">
                                        <label for="friendly_name" class="text-lg text-left"> Friendly Name
                                            <font-awesome-icon icon="info-circle" />
                                        </label>
                                        <input type="text" id="friendly_name" name="friendly_name" class="border-2 border-lightgrey w-4/5 rounded-sm">
                                    </div>
                                    <div class="flex-1 flex flex-col justify-start px-3">
                                        <label for="num-global-uifn" class="text-lg text-left">Capacity limit
                                            <font-awesome-icon icon="info-circle" />
                                        </label>
                                        <input type="text" id="num-global-uifn" name="num-global-uifn" class="border-2 border-lightgrey w-4/5 rounded-sm">
                                    </div>
                                </div>
                                <div class="flex flex-row my-2 mx-1">
                                    <div class="flex-1 flex flex-col justify-start px-3">
                                        <label for="sip_ip_address" class="text-lg text-left"> Allowed SIP IP addresses
                                            <font-awesome-icon icon="info-circle" />
                                        </label>
                                        <input type="text" id="sip_ip_address" name="sip_ip_address" class="border-2 border-lightgrey w-4/5 rounded-sm">
                                    </div>
                                    <div class="flex-1 flex flex-col justify-start px-3">
                                        <label for="24_hour_limit" class="text-lg text-left">24 hour limit(USD)
                                            <font-awesome-icon icon="info-circle" />
                                        </label>
                                        <input type="text" id="24_hour_limit" name="24_hour_limit" class="border-2 border-lightgrey w-4/5 rounded-sm">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="px-4 pb-4">
                            <div class="general_title h-8 bg-gray-100 flex flex-row justify-between items-center" style="border: solid 1px lightgrey; border-radius: 4px 4px 0px 0px;">
                                <p class="text-xl text-left py-1 px-3">Media</p>
                                <div class="py-1 px-3 cursor-pointer">
                                    <font-awesome-icon v-if="visibleMedia" icon="caret-up" class="fa-fw fa-lg mx-1" @click.prevent="visibleMedia = false"/>
                                    <font-awesome-icon v-else icon="caret-down" class="fa-fw fa-lg mx-1" @click.prevent="visibleMedia = true"/>
                                </div>
                            </div>
                            <div v-show="visibleMedia" class="" style="border: solid 1px lightgrey; border-radius:0px 0px 4px 4px;">
                                <div class="flex flex-row my-2 mx-1">
                                    <div class="flex-1 flex flex-col justify-start px-3">
                                        <label for="rtp_ip_address" class="text-lg text-left"> Allowed RTP IP addresses
                                            <font-awesome-icon icon="info-circle" />
                                        </label>
                                        <input type="text" id="rtp_ip_address" name="rtp_ip_address" class="border-2 border-lightgrey h-8 w-4/5 rounded-sm">
                                    </div>
                                    <div class="flex-1 flex flex-col justify-start px-3">
                                        <label for="symmetric_rtp" class="text-lg text-left">Force symmetric RTP
                                            <font-awesome-icon icon="info-circle" />
                                        </label>
                                        <div class="flex flex-row justify-start items-center">
                                            <select id="symmetric_rtp" name="symmetric_rtp" class="h-8 w-4/5" style="border:solid 1px lightgrey; border-radius: 4px;">
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-row my-2 mx-1">
                                    <div class="flex-1 flex flex-col justify-start px-3">
                                    </div>
                                    <div class="flex-1 flex flex-col justify-start px-3">
                                        <label for="rtp_ping" class="text-lg text-left">RTP ping
                                            <font-awesome-icon icon="info-circle" />
                                        </label>
                                        <div class="flex flex-row justify-start items-center">
                                            <select id="rtp_ping" name="rtp_ping" class="h-8 w-4/5" style="border:solid 1px lightgrey; border-radius: 4px;">
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="px-4 pb-4">
                            <div class="general_title h-8 bg-gray-100 flex flex-row justify-between items-center" style="border: solid 1px lightgrey; border-radius: 4px 4px 0px 0px;">
                                <p class="text-xl text-left py-1 px-3">CLI Rules</p>
                                <div class="py-1 px-3 cursor-pointer">
                                    <font-awesome-icon v-if="visibleCLIRules" icon="caret-up" class="fa-fw fa-lg mx-1" @click.prevent="visibleCLIRules = false"/>
                                    <font-awesome-icon v-else icon="caret-down" class="fa-fw fa-lg mx-1" @click.prevent="visibleCLIRules = true"/>
                                </div>
                            </div>
                            <div v-show="visibleCLIRules" class="" style="border: solid 1px lightgrey; border-radius:0px 0px 4px 4px;">
                                <div class="flex flex-row my-2 mx-1">
                                    <div class="flex-1 flex flex-col justify-start px-3">
                                        <label for="replace_cli_mismatch" class="text-lg text-left">On CLI mismatch
                                            <font-awesome-icon icon="info-circle" />
                                        </label>
                                        <div class="batch-action flex flex-row justify-start items-center">
                                            <select id="replace_cli_mismatch" name="replace_cli_mismatch" class="h-8 w-full" style="border:solid 1px lightgrey; border-radius: 4px;">
                                                <option  v-for="(item, index) in onCLIMismatch" :value="item" :key="index">
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="flex-1 flex flex-col justify-start px-3">
                                        <label for="replace_cli_with" class="text-lg text-left">Replace CLI with
                                            <font-awesome-icon icon="info-circle" />
                                        </label>
                                        <div class="batch-action flex flex-row justify-start items-center">
                                            <select id="replace_cli_with" name="replace_cli_with" class="h-8 w-full" style="border:solid 1px lightgrey; border-radius: 4px;">
                                                <option value="" disabled selected>Select DID Number</option>
                                                <option  v-for="(item, index) in replaceCLIWith" :value="item" :key="index">
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="px-4 pb-4">
                            <div class="general_title h-8 bg-gray-100 flex flex-row justify-between items-center" style="border: solid 1px lightgrey; border-radius: 4px 4px 0px 0px;">
                                <p class="text-xl text-left py-1 px-3">CLI Settings</p>
                                <div class="py-1 px-3 cursor-pointer">
                                    <font-awesome-icon v-if="visibleCLISettings" icon="caret-up" class="fa-fw fa-lg mx-1" @click.prevent="visibleCLISettings = false"/>
                                    <font-awesome-icon v-else icon="caret-down" class="fa-fw fa-lg mx-1" @click.prevent="visibleCLISettings = true"/>
                                </div>
                            </div>
                            <div v-show="visibleCLISettings" class="" style="border: solid 1px lightgrey; border-radius:0px 0px 4px 4px;">
                                <div class="flex flex-row justify-start items-center m-3">
                                    <RSwitch v-model="cli_setting" :checked="cli_setting" @change="onCLISettingChange" />
                                    <label class="text-lg text-left mx-2"> Allow any of my DIDs
                                        <font-awesome-icon icon="info-circle" />
                                    </label>
                                </div>
                                <div v-show="cli_setting" class="" style="border: solid 1px lightgrey; border-radius:0px 0px 4px 4px;">
                                    <div class="flex flex-row py-3 px-1 justify-start items-center">
                                        <div class="flex-1">
                                            <input type="text" class="border-2 h-8 border-lightgrey rounded-sm mx-1" placeholder="CLI">
                                        </div>
                                        <div class="flex-1">
                                            <input type="text" class="border-2 h-8 border-lightgrey rounded-sm mx-1" placeholder="Description">
                                        </div>
                                        <div class="flex-1">
                                            <select class="h-8 w-36" style="border:solid 1px lightgrey; border-radius: 4px;">
                                                <option  v-for="(item, index) in cli_setting_countries" :value="item" :key="index">
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="flex-1 mx-1">
                                            <select class="h-8 w-42" style="border:solid 1px lightgrey; border-radius: 4px;">
                                                <option value="" disabled selected>--Select Region--</option>
                                                <option  v-for="(item, index) in select_region" :value="item" :key="index">
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="flex-1 mx-1">
                                            <div class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-8 w-4/5 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;" @click.prevent="submitData">
                                                <p class="ml-1">Filter</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-row py-3 px-1 justify-start items-center">
                                       <div class="flex-1 flex flex-col px-1">
                                           <p class="text-xl text-left mx-2">Available CLI</p>
                                           <div class="available_cli_title flex flex-row justify-center itmes-center w-full bg-gray-200 rounded-sm">
                                               <div class="text-lg" style="width: 30%;">Country</div>
                                               <div class="text-lg" style="width: 30%;">Number</div>
                                               <div class="text-lg" style="width: 30%;">Description</div>
                                               <div class="text-lg" style="width: 10%;"></div>
                                           </div>
                                           <div class="cli_setting_table_section">
                                               <div v-for="(item, index) in available_cli" :value="item" :key="index" class="flex flex-row justify-center items-center py-1 cursor-pointer hover:bg-purple-100">
                                                   <div style="width: 30%">{{item.country}}</div>
                                                   <div style="width: 30%">{{item.number}}</div>
                                                   <div style="width: 30%">{{item.desc}}</div>
                                                   <div style="width: 10%"> > </div>
                                               </div>
                                           </div>

                                       </div>
                                        <div class="flex-1 flex flex-col px-1">
                                            <p class="text-xl text-left mx-2">Allowed CLI</p>
                                            <div class="available_cli_title flex flex-row justify-center itmes-center w-full bg-gray-200 rounded-sm">
                                                <div class="text-lg" style="width: 30%;">Country</div>
                                                <div class="text-lg" style="width: 30%;">Number</div>
                                                <div class="text-lg" style="width: 30%;">Description</div>
                                                <div class="text-lg" style="width: 10%;"></div>
                                            </div>
                                            <div class="cli_setting_table_section">
                                                <div v-for="(item, index) in allowed_cli" :value="item" :key="index" class="flex flex-row justify-center items-center py-1 cursor-pointer hover:bg-purple-100">
                                                    <div style="width: 30%">{{item.country}}</div>
                                                    <div style="width: 30%">{{item.number}}</div>
                                                    <div style="width: 30%">{{item.desc}}</div>
                                                    <div style="width: 10%"> X </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="px-4 pb-4">
                            <div class="general_title h-8 bg-gray-100 flex flex-row justify-between items-center" style="border: solid 1px lightgrey; border-radius: 4px 4px 0px 0px;">
                                <p class="text-xl text-left py-1 px-3">Destination Dialing Settings</p>
                                <div class="py-1 px-3 cursor-pointer">
                                    <font-awesome-icon v-if="visibleDestDialSet" icon="caret-up" class="fa-fw fa-lg mx-1" @click.prevent="visibleDestDialSet = false"/>
                                    <font-awesome-icon v-else icon="caret-down" class="fa-fw fa-lg mx-1" @click.prevent="visibleDestDialSet = true"/>
                                </div>
                            </div>
                            <div v-show="visibleDestDialSet" class="" style="border: solid 1px lightgrey; border-radius:0px 0px 4px 4px;">
                                <div class="flex flex-row my-2 mx-1">
                                    <div class="flex-1 flex flex-col justify-start px-3">
                                        <label for="dest_dial_mode" class="text-lg text-left">Destination Dialing Mode
                                            <font-awesome-icon icon="info-circle" />
                                        </label>
                                        <div class="batch-action flex flex-row justify-start items-center">
                                            <select id="dest_dial_mode" name="dest_dial_mode" class="h-8 w-full" style="border:solid 1px lightgrey; border-radius: 4px;">
                                                <option  v-for="(item, index) in destDialSets" :value="item" :key="index">
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="flex-1 flex flex-col justify-start px-3">
                                    </div>
                                </div>
                                <div class="flex flex-col justify-start itmes-center my-2 mx-4">
                                    <label class="text-lg text-left">Exceptions
                                        <font-awesome-icon icon="info-circle" />
                                    </label>
                                    <textarea class="w-full h-36 border-2 border-gray-200 rounded-sm" placeholder="Please add prefixes that should be excluded from the selected dialing mode separated by comma or new line"></textarea>
                                </div>
                                <div class="dest_noti_area my-2 mx-4 bg-purple-100 border-purple-500 rounded-lg" style="border: solid 1px #c084fc" >
                                    <p class="text-left p-3 text-purple-500">Prefix must include the Country Code, City/Area Code. Examples: 1212, 3531, 963</p>
                                </div>
                            </div>
                        </div>
                        <hr class="m-2">
                        <div class="px-4 pb-4 flex flex-row justify-between items-center">
                            <div class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;" @click.prevent="onClose">
                                <p class="mx-2">Close</p>
                            </div>
                            <div class="btn-filter flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;" @click.prevent="submitData">
                                <font-awesome-icon icon="check" class="fa-fw fa-lg mx-1"/>
                                <p class="ml-1">Submit</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WidgetModal>
</template>

<script>
    import WidgetModal from '@/shared/WidgetModal'
    import {ref} from "vue";
    import RSwitch from "../../../../../shared/RSwitch";
    export default {
        name: "CreateOutbound",
        emits: ['close'],
        components:{
            WidgetModal,
            RSwitch
        },
        props:{
            show: {
                type: Boolean,
                default: false,
            }
        },
        setup(props, {emit}){
            const visibleGeneral = ref(true);
            const visibleMedia = ref(true);
            const visibleCLIRules = ref(true);
            const visibleCLISettings = ref(true);
            const cli_setting = ref(false);
            const visibleDestDialSet = ref(false);
            const setCliSetting = (setParam) => {
                cli_setting.value = setParam
            }
            const onClose = () => {
                emit('close', 'close')
            }
            const submitData = () =>{

            }
            return{
                onClose,
                submitData,
                cli_setting,
                visibleGeneral,
                visibleMedia,
                visibleCLIRules,
                visibleCLISettings,
                visibleDestDialSet,
                setCliSetting
            }
        },
        data(){
            return{
                onCLIMismatch:[
                    {name : 'Reject call'},
                    {name: 'Replace CLI'},
                    {name: 'Send Original CLI'}
                ],
                replaceCLIWith:[],
                cli_setting_countries:[
                    {name : 'All Countries'},
                    {name: 'Australia'},
                    {name: 'Germany'}
                ],
                select_region:[
                    {name : 'region one'},
                    {name: 'region two'}
                ],
                available_cli:[
                    {country : 'Germany',
                    number: '123',
                    desc: 'description one'
                    },
                    {country : 'France',
                    number: '234',
                    desc: 'description two'
                    }
                ],
                allowed_cli:[
                    {country : 'Canada',
                        number: '123',
                        desc: 'description one'
                    },
                    {country : 'Bulgaria',
                        number: '234',
                        desc: 'description two'
                    }
                ],
                destDialSets: [
                    {name : 'Allow All'},
                    {name: 'Reject All'}
                ]
            }
        },
        methods:{
            onCLISettingChange(status){
                this.setCliSetting(status);
            }
        }
    }
</script>

<style scoped>
    .cli_setting_table_section{
        border-radius: 4px;
        height: 230px;
        overflow-y: scroll;
        border: solid 1px lightgrey;
        border-radius: 0 0 4px 4px;
        border-top: 0px;
    }
</style>
