<template>
    <div class="country_section flex flex-col justify-start bg-gray-100 rounded-md py-3">
        <div class="">
            <ul class="country_list">
                <li>Albania</li>
                <li>Argentina</li>
                <li>Australia</li>
                <li>Austria</li>
                <li>Bahrain</li>
                <li>Belgium</li>
                <li>Bosnia And Herzegovina</li>
            </ul>
            <ul class="country_list">
                <li>Brazil</li>
                <li>Bulgaria</li>
                <li>Canada</li>
                <li>Chile</li>
                <li class="text-purple-900">Colombia</li>
                <li>Costa Rica</li>
                <li>Croatia</li>
            </ul>
            <ul class="country_list">
                <li>Cyprus</li>
                <li>Czech Republic</li>
                <li>Denmark</li>
                <li>Dominican Republic</li>
                <li class="text-purple-900">Ecuador</li>
                <li>Estonia</li>
                <li>Finland</li>
            </ul>
            <ul class="country_list">
                <li>France</li>
                <li>Georgia</li>
                <li>Germany</li>
                <li>Greece</li>
                <li>Hungary</li>
                <li>Iceland</li>
                <li>Ireland</li>
            </ul>
            <ul class="country_list">
                <li>Israel</li>
                <li>Italy</li>
                <li>Japan</li>
                <li>Kazakhstan</li>
                <li class="text-purple-900">Kenya</li>
                <li>Latvia</li>
                <li>Lithuania</li>
            </ul>
            <ul class="country_list">
                <li>Luxembourg</li>
                <li>Malta</li>
                <li>Mexico</li>
                <li>Netherlands</li>
                <li>New Zealand</li>
                <li class="text-purple-900">Nicaragua</li>
                <li>Norway</li>
            </ul>
            <ul class="country_list">
                <li>Panama</li>
                <li>Peru</li>
                <li>Poland</li>
                <li>Portugal</li>
                <li>Puerto Rico</li>
                <li>Romania</li>
                <li>Russian Federation</li>
            </ul>
            <ul class="country_list">
                <li>Serbia</li>
                <li>Singapore</li>
                <li>Slovakia</li>
                <li>Slovenia</li>
                <li>South Africa</li>
                <li>Spain</li>
                <li>Sweden</li>
            </ul>
            <ul class="country_list">
                <li>Switzerland</li>
                <li class="text-purple-900">Thailand</li>
                <li>Turkey</li>
                <li class="text-purple-900">Uganda</li>
                <li>Ukraine</li>
                <li>United Kingdom</li>
                <li>United States</li>
            </ul>
            <ul class="country_list">
                <li class="text-purple-900">Venezuela</li>
            </ul>
        </div>
    </div>
    <div class="mt-6 mb-2 mx-4 flex flex-row items-center justify-between">
        <p class="text-xl">Channels Pool</p>
        <div class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;" @click.prevent="setSIPTrunkVisible">
            <font-awesome-icon icon="plus" class="fa-fw fa-lg mx-1"/>
            <p class="ml-1">Add Channels to Pool</p>
        </div>
    </div>
    <hr class="mx-4">
    <div class="p-4 bg-white">
        <div class="progress mx-4 my-6 bg-gray-100 rounded-sm"></div>
        <div class="flex flex-row justify-around items-center">
            <p>Total Channels: 0</p>
            <p>Unassigned Channels: 0</p>
            <p>Minimum limit: 0</p>
            <p>Renew date: 15.04.2022</p>
            <div class="flex flex-row items-center">
                <p>Channel Cost: $25.00/mo</p>
                <font-awesome-icon icon="info-circle" class="ml-1" />
            </div>
            <div class="flex flex-row items-center">
                <p>Metered Capacity Cost: $0.02 /min.</p>
                <font-awesome-icon icon="info-circle" class="ml-1" />
            </div>
        </div>
    </div>
    <div class="mt-6 mb-2 mx-4 flex flex-row items-center justify-between">
        <p class="text-xl">Assigned Capacity Channels</p>
        <div class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;" @click.prevent="setAddCapacityGroup">
            <font-awesome-icon icon="plus" class="fa-fw fa-lg mx-1"/>
            <p class="ml-1">Add Capacity Group</p>
        </div>
    </div>
    <hr class="mx-4">
    <div class="dest_noti_area m-4 bg-purple-100 border-purple-500 rounded-lg" style="border: solid 1px #c084fc" >
        <p class="text-left p-3 text-purple-500">Capacity groups are allocated to a group of selected DIDS, and allow additional shared call capacity on those numbers. DIDs from different countires and regions may be included in the same capacity group</p>
    </div>
    <AddChannelPool :show="visibleAddChannelPool" @close="visibleAddChannelPool=false"></AddChannelPool>
    <AddCapacityGroup :show="visibleAddCapacityGroup" @close="visibleAddCapacityGroup=false"></AddCapacityGroup>
</template>

<script>
    import {ref} from "vue";
    import AddChannelPool from '@/modules/virtualnum/components/home/Modals/AddChannelPool'
    import AddCapacityGroup from '@/modules/virtualnum/components/home/Modals/AddCapacityGroup'

    export default {
        name: "CapaExtended",
        components:{
            AddChannelPool,
            AddCapacityGroup
        },
        setup(){
            const visibleAddChannelPool = ref(false);
            const setSIPTrunkVisible = () => {
                visibleAddChannelPool.value = true;
            }
            const visibleAddCapacityGroup = ref(false);
            const setAddCapacityGroup = () => {
                visibleAddCapacityGroup.value = true;
            }
            return{
                visibleAddChannelPool,
                setSIPTrunkVisible,
                visibleAddCapacityGroup,
                setAddCapacityGroup
            }
        }
    }
</script>
<style>
    .country_section{
        overflow-x: auto;
        width: 100%;
        white-space: nowrap;
    }
    .country_list{
        display: inline-block;
        list-style: none;
        vertical-align: top;
        margin: 0 22px;
        font-size: 14px;
    }
    .progress{
        height: 20px;
        margin-bottom: 20px;
        overflow: hidden;
        background-color: #f5f5f5;
        border-radius: 4px;
        box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
        height: 30px;
    }
</style>
