<template>
    <div class="voicein-head m-1">
        <div class="top-section flex flex-row justify-between itmes-center">
            <div class="flex flex-row">
                <p class="text-2xl text-gray-600 p-4 text-left">Help</p>
            </div>
            <div></div>
        </div>
        <hr class="mx-4">
        <div class="m-5 bg-gray-100 ">
            <p class="text-lg text-left p-3">
                Trunk lines transmit voice and data, and are used to interconnect switches to form networks.<br>
                IMPORTANT - When using the services provided by DIDWW, each DID must be allocated to a trunk or a trunk group in order for the calls to be completed. This configuration is easy managed by defining trunk profiles on the "Trunks" page, and using the "Trunk" setting on the "DID Numbers" page to allocate DIDs to trunks or trunk groups.<br>
                DIDWW offers a range of trunk options as follows:
            </p>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Help"
    }
</script>

<style scoped>

</style>
