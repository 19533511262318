<template>
    <div class="country_section flex flex-col justify-start bg-gray-100 rounded-md py-3">
        <div class="">
            <ul class="country_list">
                <li>Albania</li>
                <li>Argentina</li>
                <li>Australia</li>
                <li>Austria</li>
                <li>Bahrain</li>
                <li>Belgium</li>
                <li>Bosnia And Herzegovina</li>
            </ul>
            <ul class="country_list">
                <li>Brazil</li>
                <li>Bulgaria</li>
                <li>Canada</li>
                <li>Chile</li>
                <li>Costa Rica</li>
                <li>Croatia</li>
                <li>Cyprus</li>
            </ul>
            <ul class="country_list">
                <li>Czech Republic</li>
                <li>Denmark</li>
                <li>Dominican Republic</li>
                <li>Estonia</li>
                <li>Finland</li>
                <li>France</li>
                <li>Georgia</li>
            </ul>
            <ul class="country_list">
                <li>Germany</li>
                <li>Greece</li>
                <li>Hungary</li>
                <li>Iceland</li>
                <li>Ireland</li>
                <li>Israel</li>
                <li>Italy</li>
            </ul>
            <ul class="country_list">
                <li>Japan</li>
                <li>Kazakhstan</li>
                <li>Latvia</li>
                <li>Lithuania</li>
                <li>Luxembourg</li>
                <li>Malta</li>
                <li>Mexico</li>
            </ul>
            <ul class="country_list">
                <li>Netherlands</li>
                <li>New Zealand</li>
                <li>Norway</li>
                <li>Panama</li>
                <li>Peru</li>
                <li>Poland</li>
                <li>Portugal</li>
            </ul>
            <ul class="country_list">
                <li>Puerto Rico</li>
                <li>Romania</li>
                <li>Russian Federation</li>
                <li>Serbia</li>
                <li>Singapore</li>
                <li>Slovakia</li>
                <li>Slovenia</li>
            </ul>
            <ul class="country_list">
                <li>South Africa</li>
                <li>Spain</li>
                <li>Sweden</li>
                <li>Switzerland</li>
                <li>Turkey</li>
                <li>Ukraine</li>
                <li>United Kingdom</li>
            </ul>
            <ul class="country_list">
                <li>United States</li>
            </ul>
        </div>
    </div>
    <div class="mt-6 mb-2 mx-4 flex flex-row items-center justify-between">
        <p class="text-xl">Channels Pool</p>
        <div class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;" @click.prevent="setSIPTrunkVisible">
            <font-awesome-icon icon="plus" class="fa-fw fa-lg mx-1"/>
            <p class="ml-1">Add Channels to Pool</p>
        </div>
    </div>
    <hr class="mx-4">
    <div class="p-4 bg-white">
        <div class="progress mx-4 my-6 bg-gray-100 rounded-sm"></div>
        <div class="flex flex-row justify-around items-center">
            <p>Total Channels: 0</p>
            <p>Unassigned Channels: 0</p>
            <p>Minimum limit: 0</p>
            <p>Renew date: 15.04.2022</p>
            <div class="flex flex-row items-center">
                <p>Channel Cost: $25.00/mo</p>
                <font-awesome-icon icon="info-circle" class="ml-1" />
            </div>
            <div class="flex flex-row items-center">
                <p>Metered Capacity Cost: $0.02 /min.</p>
                <font-awesome-icon icon="info-circle" class="ml-1" />
            </div>
        </div>
    </div>
    <div class="mt-6 mb-2 mx-4 flex flex-row items-center justify-between">
        <p class="text-xl">Assigned Capacity Channels</p>
        <div class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;" @click.prevent="setAddCapacityGroup">
            <font-awesome-icon icon="plus" class="fa-fw fa-lg mx-1"/>
            <p class="ml-1">Add Capacity Group</p>
        </div>
    </div>
    <hr class="mx-4">
    <hr class="mx-4">
    <div v-if="hasChannelData">
        <table-lite
                :has-checkbox="true"
                :is-slot-mode="true"
                :is-static-mode="true"
                :is-loading="table.isLoading"
                :columns="table.columns"
                :rows="table.rows"
                :total="table.totalRecordCount"
                @is-finished="tableLoadingFinish"
                @return-checked-rows="updateCheckedRows"
        >
        </table-lite>
    </div>
    <div v-else class="dest_noti_area m-4 bg-purple-100 border-purple-500 rounded-lg" style="border: solid 1px #c084fc" >
        <p class="text-left p-3 text-purple-500">Capacity groups are allocated to a group of selected DIDS, and allow additional shared call capacity on those numbers. DIDs from different countires and regions may be included in the same capacity group</p>
    </div>
    <AddChannelPool :show="visibleAddChannelPool" @close="visibleAddChannelPool=false"></AddChannelPool>
    <AddCapacityGroup :show="visibleAddCapacityGroup" @close="visibleAddCapacityGroup=false"></AddCapacityGroup>
</template>

<script>
    import {computed, onMounted, reactive, ref } from 'vue'
    import TableLite from "vue3-table-lite";
    import AddChannelPool from '@/modules/virtualnum/components/home/Modals/AddChannelPool'
    import AddCapacityGroup from '@/modules/virtualnum/components/home/Modals/AddCapacityGroup'

    export default {
        name: "CapaStandard",
        components:{
            TableLite,
            AddChannelPool,
            AddCapacityGroup
        },
        setup(){
            const hasChannelData = ref(true);
            const table = reactive({
                isLoading: false,
                columns: [
                    {
                        label: "Channels List",
                        field: "channelList",
                        width: "20%",
                        sortable: true
                    },
                    {
                        label: "Assigned To",
                        field: "assignedTo",
                        width: "50%"
                    },
                    {
                        label: "Actions",
                        field: "actions",
                        width: "30%"
                    }
                ],
                rows: [],
                searchResult:[],
                totalRecordCount: computed(() => {
                    return table.rows.length;
                })
                // sortable: {
                //   order: "itemname",
                //   sort: "asc",
                // },
            });
            const tableLoadingFinish = (elements) => {
                table.isLoading = false;
                Array.prototype.forEach.call(elements, function (element) {
                    if (element.classList.contains("qty-input")) {
                        element.addEventListener("change", function () {
                            //this.dataset.subtotal =  this.dataset.selfQTY + this.dataset.activeprice;
                        });
                    }
                });
            };
            /** Row checked event */
            const updateCheckedRows = (rowsKey) => {};

            const visibleAddChannelPool = ref(false);
            const setSIPTrunkVisible = () => {
                visibleAddChannelPool.value = true;
            }
            const visibleAddCapacityGroup = ref(false);
            const setAddCapacityGroup = () => {
                visibleAddCapacityGroup.value = true;
            }

            return{
                hasChannelData,
                table,
                updateCheckedRows,
                tableLoadingFinish,
                visibleAddChannelPool,
                setSIPTrunkVisible,
                visibleAddCapacityGroup,
                setAddCapacityGroup
            }
        },
        mounted(){
            this.table.rows = [
                {
                    channelList: '0 shared | 2 metered',
                    assignedTo: 'Pay Per Minute',
                    actions: 'actions...'
                }
            ]
        },

    }
</script>

<style scoped>

.country_section{
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
}
.country_list{
    display: inline-block;
    list-style: none;
    vertical-align: top;
    margin: 0 22px;
    font-size: 14px;
}
</style>
