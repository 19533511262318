<template>
    <WidgetModal :show="show">
        <div class="lg:max-w-883p w-full mx-auto h-full px-3">
            <div class="overflow-x-hidden" style="min-height: calc(100% - (1.75rem * 2))">
                <div class="my-6">
                    <div class="bg-white relative rounded-lg" style="border: solid 1px lightgrey;">
                        <div class="absolute top-6 right-8 h-6 w-6 cursor-pointer">
                            <img src="@/assets/images/close.svg" class="hover:opacity-50" alt=""/>
                        </div>
                        <div class="py-6 px-4">
                            <p class="text-2xl text-left pl-1">Create Capacity Group</p>
                            <hr class="mt-3">
                        </div>
                        <div class="form-group flex flex-row justify-center items-center w-full">
                            <label for="group_name" class="text-md w-1/3"> Group name&nbsp;: </label><br>
                            <input type="text" id="group_name" name="group_name" class="border-2 border-gray-200 round-sm w-1/2 mx-4 h-8" placeholder="Enger a group name">
                        </div>
                        <div class="form-group flex flex-row justify-center items-center w-full">
                            <label for="shared_channels" class="text-md w-1/3"> Assigned shared channels&nbsp;: </label><br>
                            <input type="text" id="shared_channels" name="shared_channels" class="border-2 border-gray-200 round-sm  mx-4 h-8" placeholder="0">
                            <font-awesome-icon icon="info-circle" />
                            <p class="pl-2 text-red-500"> Please free up, or <a href="" class="text-gray-500 underline">buy channel</a></p>
                        </div>
                        <div class="form-group flex flex-row justify-center items-center w-full">
                            <label for="metered_channels" class="text-md w-1/3"> Assigned shared channels&nbsp;: </label><br>
                            <input type="text" id="metered_channels" name="metered_channels" class="border-2 border-gray-200 round-sm  mx-4 h-8" placeholder="0">
                            <font-awesome-icon icon="info-circle" />
                            <p class="pl-2 text-red-500 invisible"> Please free up, or <a href="" class="text-gray-500 underline">buy channel</a></p>
                        </div>
                        <hr class="my-4">
                        <div class="px-4 pb-4 flex flex-row justify-between items-center">
                            <div class="btn-addnew flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;">
                                <p class="mx-4">Close</p>
                            </div>
                            <div class="btn-filter flex flex-row justify-center items-center btn-order-new bg-app-purple h-10 m-1 mx-4 p-1.5 text-white cursor-pointer hover:text-gray-500" style="border-radius: 6px;">
                                <font-awesome-icon icon="check" class="fa-fw fa-lg mx-1"/>
                                <p class="ml-1">Submit</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WidgetModal>
</template>

<script>
    import {ref, computed, reactive, onMounted} from 'vue'
    import WidgetModal from '@/shared/WidgetModal'
    export default {
        name: "AddCapacityGroup",
        emits: ['close'],
        components:{
            WidgetModal
        },
        props:{
            show: {
                type: Boolean,
                default: false,
            }
        },
        
    }
</script>

<style scoped>

</style>
