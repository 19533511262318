<template>
    <div>Call log page</div>
</template>

<script>
    export default {
        name: "Calllogs"
    }
</script>

<style scoped>

</style>
