<template>
  <div v-if="isDashboard" class="text-black"><Dashboard /></div>
  <div v-else-if="isCoverage" class="text-black"><Coverage /></div>
  <div v-else-if="isUserdids" class="text-black"><Userdids /></div>
  <div v-else-if="isTrunks" class="text-black"><Trunks /></div>
  <div v-else-if="isCapacity" class="text-black"><Capacity /></div>
  <div v-else-if="isCalllogs" class="text-black"><Calllogs /></div>
  <div v-else-if="isSmslogs" class="text-black"><Smslogs /></div>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import Coverage from '@/modules/virtualnum/pages/Coverage'
import Dashboard from '@/modules/virtualnum/pages/Dashboard'
import Userdids from '@/modules/virtualnum/pages/Userdids'
import Trunks from '@/modules/virtualnum/pages/Trunks'
import Capacity from '@/modules/virtualnum/pages/Capacity'
import Calllogs from '@/modules/virtualnum/pages/Calllogs'
import Smslogs from '@/modules/virtualnum/pages/Smslogs'

export default {
  name: 'index',
  components: {
    Coverage,
    Dashboard,
    Userdids,
    Trunks,
    Capacity,
    Calllogs,
    Smslogs
  },
  setup () {
    const route = useRoute()
    const isDashboard = computed(() => {
      return ['virtual-dashboard'].includes(route.name)
    })
    const isCoverage = computed(() => {
      return ['virtual-coverage'].includes(route.name)
    })
    const isUserdids = computed(() => {
      return ['virtual-user-dids'].includes(route.name)
    })
    const isTrunks = computed(() => {
      return ['virtual-trunks'].includes(route.name)
    })
    const isCapacity = computed(() => {
      return ['virtual-capacity-pools'].includes(route.name)
    })
    const isCalllogs = computed(() => {
      return ['virtual-call-logs'].includes(route.name)
    })
    const isSmslogs = computed(() => {
      return ['virtual-sms-logs'].includes(route.name)
    })
    return {
      isDashboard,
      isCoverage,
      isUserdids,
      isTrunks,
      isCapacity,
      isCalllogs,
      isSmslogs
    }
  }
}
</script>

<style scoped></style>
